import React, { useState } from 'react';
import { Avatar, Box, Container, CssBaseline, Link, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles'
import { TextField, Button } from '@mui/material';
import { LockOutlined } from "@mui/icons-material";

const useStyles: any = makeStyles(theme => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,

        '& .MuiTextField-root': {
            margin: 1,
            width: '300px',
        },
        '& .MuiButtonBase-root': {
            margin: 2,
        },
    },
    paper: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: 1,
        //backgroundColor: theme.palette.secondary.main,
    },
}));

function Copyright() {
    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography sx={{fontSize: '10px'}} lineHeight={'10px'} variant="caption" color="textSecondary" align="center">
                This Web-portal is an Alcatel-Lucent Enterprise initiative. The Alcatel-Lucent name and logo are trademarks of Nokia used under license by ALE.
            </Typography>
            <Typography variant="caption" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://www.al-enterprise.com/">
                    Alcatel-Lucent Enterprise
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Box>
    );
}

const Form = ({ handleClose }) => {
    const classes = useStyles();
    // create state variables for each input
    const [loginEmail, setLoginEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        handleClose(loginEmail, password);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.root} onSubmit={handleSubmit}>
                    <TextField
                        label="loginEmail"
                        variant="filled"
                        required
                        value={loginEmail}
                        onChange={e => setLoginEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        variant="filled"
                        type="password"
                        required
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Box mt = {2}>
                        <Button variant="contained" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Signin
                        </Button>
                    </Box>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};

export default Form;
