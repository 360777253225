import React from 'react'
import { Button } from '@mui/material';
import { Menu, MenuHeader, MenuItem, MenuButton, SubMenu, MenuItemProps, SubMenuProps } from '@szhsin/react-menu';

export namespace AleMenu {
    export interface UrlProps {
        href: string
        description?: string
        target: "_blank"
        rel: "noopener noreferrer"
    }
    export interface Item {
        title: string
        type: 'menuheader' | 'submenu' | 'url' | 'route'
        description?: string
        data?: UrlProps | MenuItemProps | SubMenuProps
        menuItems?: Item[]
    }
}

interface MenuCreateProps {
    menus
    color?
    direction?
}

export function CreateMenu(props: MenuCreateProps) {
    let { menus, color, direction } = props;
    return (
        <>
            {menus.map((menu: AleMenu.Item)=><ParsedMenuProperties key={`M${menu.title}`} menuItem={menu} color={color} direction={direction} />)}
        </>
    );
}

interface MenuProps {
    menuItem
    color?
    direction?
}

function ParsedMenuProperties(menuprops: MenuProps) {
    let {menuItem, ...props} = menuprops;
    //console.log(`ParsedMenuProperties`, props);
    if (Array.isArray(menuItem)) return menuItem.map((menu: AleMenu.Item)=><ParsedMenuProperties key={`M${menu.title}`} direction={props.direction} menuItem={menu} color={props.color}/>)
    let menu: AleMenu.Item = menuItem;
    //console.log(`-ParsedMenuProperties`, menuItem.type);
        let subMenus;
        switch(menu.type) {
            case 'menuheader':
                subMenus = ParsedMenuProperties({menuItem:menu.menuItems, ...props})
                subMenus.unshift(<MenuHeader key={menu.title} style={{fontWeight:'bolder'}}>{menu.title}</MenuHeader>)
                //console.log(`--ParsedMenuProperties menuheader`, subMenus);
                return subMenus;
            case 'url':
                //return <></>
                return <Button key={menu.title} role={'menuitem'} href={(menu.data as AleMenu.UrlProps).href} target={(menu.data as AleMenu.UrlProps).target} rel={(menu.data as AleMenu.UrlProps).rel} style={{width:'100%', height:'20px', fontSize:'12px', justifyContent:'end'}}>{menu.title}</Button>
            case 'submenu':
                //subMenus = ParsedMenuProperties({menuItem:menu.menuItems})
                //subMenus.unshift(<Menu key={menu.title} arrow menuButton={<Button sx={{ my: 2, color: 'white', display: 'block' }}>{menu.title}</Button>} ></Menu>);
                subMenus = <Menu direction={props.direction?props.direction:'bottom'} key={menu.title} arrow menuButton={<Button sx={{ my: 2, color: `${props.color?props.color:'white'}`, display: 'block' }}>{menu.title}</Button>} ><ParsedMenuProperties menuItem={menu.menuItems} direction={props.direction} color={props.color}/></Menu>
                //console.log(`--ParsedMenuProperties submenu`, subMenus);
                return subMenus;
            default:
                console.log(`--ParsedMenuProperties default`, menu.type);
                return <></>
        }
    
}