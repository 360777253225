import to from 'await-to-js';
import {inspect} from 'util';
import RainbowCore from "../rainbow/rainbow-core";
import {rainbowService} from "../index";
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';


export class TokenService {
  rb: RainbowCore = rainbowService;
  constructor() {
    this.isValidToken()
  }

  getToken(): string {
    let token = 'DUMMY';
    try {
      token = localStorage.getItem(ACCESS_TOKEN);
    } catch (e) {
      return 'DUMMY';
    }
    return token || 'DUMMY';
  }

  async isValidToken(token?: string):Promise<boolean> {
    token = token?token:this.getToken();
    if (!token) return Promise.resolve(false);
    this.rb.rest.token = token;
    let [e,r] = await to(this.rb.rest.authentication.validateJWT.get);
    console.log(`[TOKEN] :: validateToken response: `,token, r,e);
    if (e) {
      //console.log(`[TOKEN] :: validateToken invalid: ${token}`)
      if (token == this.getToken()) this.removeToken()
      return Promise.resolve(false);
    } else {
      return Promise.resolve(true);
    }
  }

  async getUserToken() {
    let [e,r] = await to(this.rb.rest.authentication.authorizeOauth.create);
    console.warn(`[TOKEN] :: Received getusertoken response`,r,e)
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  saveToken(token): void {
    if (!token) console.warn(`[TOKEN] :: Token has been cleared`);
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  async saveRefreshToken(refreshToken?) {
    let e;
    if (!refreshToken) {
      [e,refreshToken]= await to(this.rb.sdk.connection.refreshToken());
    }
    console.log(`[TOKEN] :: got refreshToken`, refreshToken, e);
    localStorage.setItem(REFRESH_TOKEN, refreshToken
    );
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }
}
