import { Dialog } from "@mui/material";
import { rainbowService, tokenService } from "../..";
import { HighlightOff } from "@mui/icons-material";
import Form from './Form';
import { closeSnackbar, enqueueSnackbar as toast } from 'notistack';
import React, { useEffect, useState } from "react";

const ModalDialog = ({ open, handleClose }) => {
    return (
        // props received from App.js
        <Dialog open={open} onClose={handleClose}>
            <Form handleClose={handleClose} />
        </Dialog>
    );
};



export default function RainbowLogincomponent(props) {
    let {open, isStarted} = props;
    const [openloginDialog, setOpenloginDialog] = useState(false);
    const [starting, setStarting] = useState<boolean>(false);
    useEffect(()=>{
        console.log(`[LOGIN] ::  Init state to ${rainbowService.state.started?"Logout":"Login"}`)
        rainbowService.onStarted.subscribe((started=>{isStarted(started)}));
        if (rainbowService.state.started) {logout()} else loginToRainbow();
        return ()=>{console.log(`[LOGIN] ::  Component closed: started is ${rainbowService.state.started}`)};
    }, [])
    
    const handleCloseLoginDialog = (loginEmail, password) => {
        setOpenloginDialog(false)
        let sId = toast("Please wait while we connect to Rainbow ...", {persist:true, variant: 'info', anchorOrigin:{ vertical: 'bottom', horizontal: 'center' } })
        rainbowService.login(loginEmail, password).then((data) => {
            console.warn(`[LOGIN] :: Logged in`, rainbowService.sdk.contacts.getConnectedUser().fullname, data);
            tokenService.saveToken(data.token);
            toast("Login succesfull", { variant: 'success',anchorOrigin:{ vertical: 'bottom', horizontal: 'center' }, onClose:()=>{closeSnackbar(sId)} })
        }).catch(err => {
            console.error(`[LOGIN] :: Error Rainbow login`, err);
            toast("Login failed", { variant: 'error',anchorOrigin:{ vertical: 'bottom', horizontal: 'center' }, onClose:()=>{closeSnackbar(sId)}})
        })
    }

    const loginToRainbow = () => {
        let sId;
        tokenService.isValidToken().then(valid => {
            if (!valid) {
                //console.warn(`[Controlpanel] :: Opening login dialog`);
                setOpenloginDialog(currentState => true)
            } else {
                //console.warn(`[Controlpane] :: token is valid, login with token`);
                
                sId = toast("Auto-connecting to Rainbow, Login started ...", {persist:true, variant: 'info', anchorOrigin:{ vertical: 'bottom', horizontal: 'center' } })
                setStarting(true);
                rainbowService.loginWithToken(tokenService.getToken()).then(data => {
                    console.warn(`[LOGIN] :: Logged in with token`, rainbowService.sdk.contacts.getConnectedUser(), data);
                    tokenService.saveToken(data.token);
                    setStarting(false)
                    //setDisplayName(rainbowService.sdk.contacts.getConnectedUser().fullname);
                    toast("Login succesfull", { variant: 'success', anchorOrigin:{ vertical: 'bottom', horizontal: 'center' }, onClose:()=>{closeSnackbar(sId)}})
                })
            }
        }).catch(e => {
            console.warn(`[LOGIN] :: Error in validateToken`);
            toast("Error when trying to auto-login, please try again", { variant: 'error', anchorOrigin:{ vertical: 'bottom', horizontal: 'center' }, onClose:()=>{closeSnackbar(sId)}})
            tokenService.removeToken();
            setStarting(false)
        });
    }
    const logout = () => {
        tokenService.removeToken();
        rainbowService.logout().then(() => {
            toast("Login information cleared, logged out", { variant: 'info', anchorOrigin:{ vertical: 'bottom', horizontal: 'center' } })
        })
    }

    return (
        <>
            <ModalDialog open={openloginDialog} handleClose={handleCloseLoginDialog} />
        </>
    )

}