import {throwError} from 'rxjs';
//import {environment} from '../environments/environment';
const API_URL = 'https://www.ale-euno.com';
const API_URL_DEV = 'https://localhost:4200';
export const APP_ID = '117c3b60103b11e9add8932b358ef81d';

const redirectURL = `https://openrainbow.com/api/rainbow/authentication/v1.0/oauth/authorize?response_type=token&client_id=${APP_ID}&redirect_uri=${process.env.NODE_ENV!='development'?API_URL:API_URL_DEV}&state=piteam2&scope=all`


export class AuthService {
  redirectUrl = '';

  /*private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }*/

  private static log(message: string): any {
    console.log(message);
  }

  constructor() {
    this.redirectUrl = redirectURL;
  }

  redirect() {
    //console.warn(`[AUTH] :: Redirecting for Auth`);
    window.location.replace(this.redirectUrl);
  }
}
