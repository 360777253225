import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import { Menu as _Menu, MenuItem as _MenuItem, MenuHeader as _MenuHeader } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { AleMenu, CreateMenu } from '../../components/menu/menu';
import RainbowLogincomponent from '../../components/login/default';
import { rainbowService } from '../..';


export interface Props {
  pages: string[]
  menus: AleMenu.Item[]
  aleMenu: string[]
}


function ResponsiveAppBar(props) {
  let {pages, menus, aleMenu} = props;
  
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [openLoginWindow, setOpenLoginWindow] = React.useState<boolean>(false);
  const [loggedIn, setLoggedIn] = React.useState<boolean>(rainbowService.isStarted)
  
  React.useEffect(()=>{
    console.log(`[APPBAR] :: menu structure changed`, props);
  }, [pages, menus, aleMenu])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSelectUserMenu = (item: string) => {
    setAnchorElUser(null);
    console.log(`handleSelectUserMenu:`, item );
    switch (item) {
      case aleMenu[2]:
        setOpenLoginWindow(true);
        break;
      default:
    }
  };

  return (
    <AppBar color='info' position="sticky" sx={{ mt: '5px', width: '100%', borderRadius: '10px' }} >
      <Container maxWidth="xl">
      {openLoginWindow && <RainbowLogincomponent open={openLoginWindow} isStarted={(started: boolean)=>{setLoggedIn(started); setOpenLoginWindow(false)}} />}
        <Toolbar variant="dense" disableGutters sx={{ width: '100%' }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{

              display: { xs: 'none', md: 'flex' },
              //fontFamily: 'monospace',
              //fontWeight: 700,
              //letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            ALE Antwerp Demosite
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <_Menu arrow menuButton={<IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              //onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>}>
            <CreateMenu menus={menus} color={'black'} direction={'right'}/>
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </_Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            ALEA
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <CreateMenu menus={menus}/>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Divider sx={{ background: 'white' }} orientation='vertical' flexItem variant='middle' role="presentation"></Divider>
            <Box ml={'10px'} sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src={`${loggedIn?`${rainbowService.me.avatar}`:"./favicon.ico"}`} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {aleMenu.map((setting) => (
                  <MenuItem key={setting} onClick={()=>handleSelectUserMenu(setting)}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
