import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { enqueueSnackbar as toast } from 'notistack';
import RainbowCore, { Rainbow } from './rainbow/rainbow-core';
import { TokenService } from './services/token.service';
import { AuthService } from './services/auth.service';
import { BrowserRouter } from 'react-router-dom';

export const rainbowService: RainbowCore = new RainbowCore({config:new Rainbow.Config({
  credentials:{appID: "117c3b60103b11e9add8932b358ef81d",
      appSecret: "OIfUJIR7JwU2EJTkGeqaVftvpUCgSi5EGXHltDov2AkMSKjYYKuJJ7x6cQqCWiQW"
  }})});
export const tokenService: TokenService = new TokenService()
export const authService: AuthService = new AuthService();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate:(reg)=>{
    console.log(`Received onUpdate request`); 
    reg.waiting.postMessage({ type: "SKIP_WAITING" }) ;
    toast("We're reloading the app, hold on ...", {variant: 'PwaMessage'})
    //res.waiting.postMessage({ type: "SKIP_WAITING" }) ;
    setTimeout(()=>{window.location.reload();}, 10000) 
  },
  onSuccess:(res)=>{console.log(`Received onSuccess request`); setTimeout(()=>{/*window.location.reload();*/}, 5000) }
});
