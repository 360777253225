import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#6b489d',
    },
    secondary: {
      main: '#0085ca',
    },
    success: {
      main: '#34b233',
    },
    warning: {
      main: '#ff4500',
    },
    error: {
      main: '#a50034',
    },
  },
  typography: {
    fontFamily: 'Fira+Sans:wght@300;400;700',
  },
};