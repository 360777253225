import * as React from 'react';
import Marquee from "react-fast-marquee";
import { rainbowService } from '../..';
import { Rainbow } from '../../rainbow/rainbow-core';
import { Avatar, Badge, Box, Button, Card, CardHeader, Chip, IconButton, Typography } from '@mui/material';
import { MenuOutlined } from '@mui/icons-material';
import { closeSnackbar, enqueueSnackbar as toast } from 'notistack';

export function DefaultMessageTicker(props) {
    let { conversationId } = props;
    const [doPlay, setDoPlay] = React.useState(true);
    const [messages, setMessages] = React.useState<any[]>([
        { contact: { avatar: null, fullname: "Admin" }, bubbleName: "", side: "R", data: "Login to Rainbow to see your messages arrive ..." },
        //{ contact: { avatar: null, fullname: "Pieter Jansens" }, bubbleName: "Test Bubble", side: "R", data: "Hi there" }
    ]);
    React.useEffect(() => {
        Rainbow.IMessages.ONRECEIVED.subscribe(data => {
            console.log(`[TICKER] :: Received new message`, data);
            let { message, conversation } = data;
            (async () => {
                toast(`${message.data}`, { variant: 'info',anchorOrigin:{ vertical: 'bottom', horizontal: 'right'} })
                message.bubbleName = conversation['bubbleName'] ? conversation['bubbleName'] : "";
                message.contact = await rainbowService.sdk.contacts.searchById(conversation.contactId);
                messages.push(...[(message as Rainbow.Message[])].filter((msg: any) => msg.side != 'ADMIN'));
                setMessages(messages);
                setDoPlay(false);
                await new Promise(resolve => setTimeout(resolve, 100));
                setDoPlay(true);
            })();

        })
        rainbowService.onStarted.subscribe(started => {
            if (!started) {
                setMessages([]);
                setDoPlay(false);
                return
            }
            if (conversationId) {
                getMessages(conversationId).then(res => {
                    setMessages(res);
                })
            } else if (rainbowService.state.started) {
                (async () => {
                    let conversations: Rainbow.Conversation[] = await rainbowService.sdk.conversations.getAllConversations();
                    console.log(`[TICKER] :: Conversations`, conversations);
                    let msgs: Rainbow.Message[] = messages;
                    try {
                        for (let conversation of conversations) {
                            console.log(`[TICKER] :: getting messages for conv`, conversation)
                            let res: Rainbow.Conversation;
                            if (conversation.lastMessage == "") break;
                            res = await rainbowService.sdk.im.getMessagesFromConversation(conversation.id, 3);
                            console.log(`[TICKER] :: got messages:`, res)
                            if (res) {
                                for (let m of res.messages) {
                                    m.bubbleName = res['bubbleName'] ? res['bubbleName'] : "";
                                    m.contact = await rainbowService.sdk.contacts.searchById(m.senderId);
                                }
                                messages.push(...(res.messages as Rainbow.Message[]).filter(msg => msg.side != 'ADMIN'));
                                console.log(`[TICKER] :: Added ${res.messages.length} messages, total is ${msgs.length}`, res)
                                setMessages(messages);
                                setDoPlay(false);
                                await new Promise(resolve => setTimeout(resolve, 100));
                                setDoPlay(true);
                            }
                        }
                    } catch (err) { console.error(`[TICKER] :: got error`, err) }
                    setMessages(messages);
                    await new Promise(resolve => setTimeout(resolve, 100));
                    setDoPlay(false);
                    await new Promise(resolve => setTimeout(resolve, 100));
                    setDoPlay(true);
                    console.log(`[TICKER] :: Loaded ${messages.length} messages`, messages)
                })();
            } else {
                console.warn(`[TICKER] :: Rainbow state is not started, skipping load messages`)
            }
        })
        /*setTimeout(() => {
            let m = messages.push({ contact: { avatar: null, fullname: "Fred" }, bubbleName: "Test Bubble", side: "R", data: "Look here" }            )
            setMessages(messages);
            setTimeout(() => {
                (async () => {
                    
                        setDoPlay(false);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        setDoPlay(true);
                    
                })();
            }, 1000);
            console.log(`[TICKER] :: Added message`)
        }, 10000);*/
        const getMessages = async (cId, num?) => { return await rainbowService.sdk.im.getMessagesFromConversation(cId, num ? num : 10) }

    }, [conversationId])
    return (

        <Marquee play={doPlay} pauseOnHover={true} >
            {messages.map((msg: Rainbow.Message, idx) => {
                return (
                    <Chip
                        sx={{ maxWidth: "100%", margin: '2px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        key={`ticker${idx}`} color={`${msg.side == 'L' ? "primary" : "secondary"}`}
                        label={
                            <CardHeader sx={{ maxWidth: '100%' }} disableTypography={true}
                                title={<Box display='flex' flexDirection={'row'} alignItems={'center'}>
                                    <Typography
                                        variant="h3"
                                        color="white"
                                        textAlign="left"
                                        fontSize="15px"
                                        mt='2px'
                                        mb='-2px'
                                    >{msg['contact'].fullname}
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        color="white"
                                        textAlign="left"
                                        fontSize="11px"
                                        noWrap={true}
                                        maxWidth={{ lg: '200px', xs: '100px' }}
                                        ml="5px"
                                        mt='4px'
                                        mb='-2px'
                                    >{msg['bubbleName'] ? `(In  '${msg['bubbleName']}')` : ""}</Typography></Box>}
                                subheader={
                                    <Typography
                                        //variant="caption"
                                        color="white"
                                        textAlign="left"
                                        fontSize="13px"
                                        fontStyle='italic'
                                        maxWidth={{ lg: '400px', xs: '200px' }}
                                        noWrap={true}
                                    //style={{ whiteSpace: "nowrap", wordBreak: "break-all", overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >{msg.data}</Typography>
                                }
                                action={<IconButton onClick={() => { console.log(`[TICKER] :: clicked menu`) }} sx={{ marginRight: '0px' }}><MenuOutlined sx={{ color: 'white' }} /></IconButton>}>{msg.data}
                            </CardHeader>}
                        avatar={<Avatar alt={msg['contact'].fullname} src={msg['contact'].avatar} />}
                    />)
            })}
        </Marquee>

    )
}
