import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { SnackbarProvider, enqueueSnackbar as toast } from 'notistack';
import Zoom from './notistack/zoom';
import { Box, ThemeProvider, createTheme } from "@mui/material";
import Main from "./pages/main";
import { themeOptions } from './themes/aleTheme'
import { PwaMessage } from "./notistack/customVariants";
declare const VERSION: string;
declare module 'notistack' {
  interface OptionsObject {
    description?: string;
  }
  interface VariantOverrides {     
    PwaMessage: {};    
    // adds `reportComplete` variant and specifies the "extra" props it takes in options of `enqueueSnackbar`
    /*reportComplete: {         
      allowDownload: boolean  
    }*/
  }
}

function App() {
  console.log("*** Welcome to Rainbow Web SDK Starter Kit for React ***");
  const { pathname } = useLocation();
  const theme = createTheme(themeOptions);
  
  useEffect(() => { 
    toast(`Application version ${VERSION ? VERSION : 'Unknown'} started`, { variant: 'warning' });
  }, [])
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <div className="App" >
      <ThemeProvider theme={theme}>
        <SnackbarProvider 
        dense anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        TransitionComponent={Zoom}
        Components={{PwaMessage: PwaMessage}}
        />
        <Routes>
          <Route path="/main" element={<Main />} />
          <Route path="*" element={<Navigate to="/main" />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
