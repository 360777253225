import {Subject, ReplaySubject, Observable, BehaviorSubject, Subscription, delay} from 'rxjs';
import axios, {AxiosRequestConfig} from 'axios';
import RainbowSDK from 'rainbow-web-sdk';
import React from "react";
import {rainbowService} from "../index";

window['rainbowSDK'] = rainbowSDK;



var rainbowSDK: Rainbow.SDK = RainbowSDK;
var _events:{ [key: string]: ReplaySubject<any> } = {};
const addEventES6 = (event) => {
    _events[event] = new ReplaySubject<any>();
    //console.log(`[RB-CORE] :: ReactRainbow Event ${event.toUpperCase()} added`);
    document.addEventListener(event, (data) => {
        console.log(`[RB-CORE] :: ReactRainbow Event ${event.toUpperCase()} triggered`);
        _events[event].next((data.detail) ? data.detail : {});
    });
}
const addEventGroup = (group) => {
    if (group == 'sdk') {
        for (let event of Object.keys(rainbowSDK).filter(k => k.startsWith('RAINBOW_ON'))) {
            addEventES6(rainbowSDK[event]);
        }
    } else {
        for (let event of Object.keys(rainbowSDK[group]).filter(k => k.startsWith('RAINBOW_ON'))) {
            addEventES6(rainbowSDK[group][event]);
        }
    }
}
const subscribe = (event)  => {
    console.log(`[RB-CORE] :: Received event subscription request for ${event.toUpperCase()} (current num subscribers is ${_events[event]?_events[event].observers.length:'Unknown'})`);
    return _events[event] as ReplaySubject<any>;
}


const APP_ID = "117c3b60103b11e9add8932b358ef81d";
const APP_SECRET = "OIfUJIR7JwU2EJTkGeqaVftvpUCgSi5EGXHltDov2AkMSKjYYKuJJ7x6cQqCWiQW"

type IProps = {
    config?: Rainbow.Config
    children?: any[],
}
export type IState = {
     loaded?: boolean
     initialised?: boolean
     started?: boolean
}

export default class RainbowCore { // extends React.Component<IProps, IState>

    state: IState = {}
    props: IProps = {}

    sdk: Rainbow.SDK; //for compatibility
    me: Rainbow.Contact; //for compatibility
    public rest: Rainbow.REST = new Rainbow.REST();
    public webRTC: Rainbow.IWebRTC;
    //public conference: Rainbow.IConference;
    public iConferences: Rainbow.IConferences;

    public onLoaded: ReplaySubject<boolean> = new ReplaySubject();
    public onReady: ReplaySubject<boolean> = new ReplaySubject();
    public onStarted: ReplaySubject<boolean> = new ReplaySubject();
    isStarted: boolean = false;
    public onInitialised: ReplaySubject<boolean> = new ReplaySubject();
    public onLostConnection: ReplaySubject<boolean> = new ReplaySubject();

    private _audioInputDevices: MediaDeviceInfo[]=[];
    get audioInputDevices() {return this._audioInputDevices}
    private _audioOutputDevices: MediaDeviceInfo[]=[];
    get audioOutputDevices() {return this._audioOutputDevices}
    private _videoInputDevices: MediaDeviceInfo[]=[];
    get videoInputDevices() {return this._videoInputDevices}
    onMediadevicelistChanged: ReplaySubject<MediaDeviceInfo[]> = new ReplaySubject<MediaDeviceInfo[]>();
    isChromeBrowser = false;
    async updateMedaDevices(): Promise<MediaDeviceInfo[]> {
        /*  Get the list of available devices */
        return new Promise<MediaDeviceInfo[]>((resolve,reject)=>{
            navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
                //console.log('[RB-CORE] :: ReactRainbow mediadevices:', devices);
                this._audioInputDevices = devices.filter(d => d.kind == 'audioinput');
                this._audioOutputDevices = devices.filter(d => d.kind == 'audiooutput');
                this._videoInputDevices = devices.filter(d => d.kind == 'videoinput');
                this.onMediadevicelistChanged.next(devices)
                resolve(devices);
            }).catch(err=>{
                console.error(`[RB-CORE] :: Error retrieving mediadevices`, err);
                reject();
            })
        })
    }
    getUserMediaRights() {
        return navigator.mediaDevices.getUserMedia({audio: true, video: true})
    }

    private askMediaRightsForChrome() {
        //console.log('[RB-CORE] :: ReactRainbow askMediaRightsForChrome');
        this.getUserMediaRights().then((stream) => {

            /* Stream received which means that the user has authorized the application to access to the audio and video devices. Local stream can be stopped at this time */
            stream.getTracks().forEach(function(track) {
                track.stop();
            });

            /*  Get the list of available devices */
            this.updateMedaDevices().then((devices) => {
                rainbowSDK.webRTC.useMicrophone(this.audioInputDevices[0].deviceId);
                rainbowSDK.webRTC.useCamera(this.videoInputDevices[0].deviceId);
                rainbowSDK.webRTC.useSpeaker(this.audioOutputDevices[0].deviceId);
            }).catch((error) => {
                /* In case of error when enumerating the devices */
            });
        }).catch((error) => {
            /* In case of error when authorizing the application to access the media devices */
        });
    }

    /*private _events = {};
    addEventES6(event) {
        this._events[event] = new ReplaySubject<any>();
        document.addEventListener(event, (data) => {
            //console.log(`[ALE-RB] :: ReactRainbow Event ${event.toUpperCase()} triggered`);
            this._events[event].next((data.detail) ? data.detail : {});
        });
    }
    addEventGroup(group) {
        if (group == 'sdk') {
            for (let event of Object.keys(rainbowSDK).filter(k => k.startsWith('RAINBOW_ON'))) {
                this.addEventES6(rainbowSDK[event]);
            }
        } else {
            for (let event of Object.keys(rainbowSDK[group]).filter(k => k.startsWith('RAINBOW_ON'))) {
                this.addEventES6(rainbowSDK[group][event]);
            }
        }
    }
    subscribe(event, callback) {
        //console.log('[ALE-RB] :: Received event subscription request for %s', event);
        return this._events[event].subscribe(callback);
    }*/

    constructor(props) {
        //super(props)
        //console.log(`RB-CORE] :: construct`, props, this.props)
        this.props = props;
        this.state = {initialised:false, loaded: false, started: false}
        navigator.mediaDevices.ondevicechange = (event) => {
            console.warn(`[RB-CORE] :: change in mediadevice list, updating list`);
            this.updateMedaDevices();
        }
        this.loadEvents();
        this.loadListeners();
        this.sdk = rainbowSDK;
        rainbowSDK.config.useNewModels(true);
        //console.warn(`RB-CORE] :: Rainbow config`, rainbowSDK.config.getCurrentConfig())
        rainbowSDK.start();
        rainbowSDK.load();
        this.webRTC = new Rainbow.IWebRTC();
        this.iConferences  = new Rainbow.IConferences();
        //this.conference = new Rainbow.IConference();
    }

    loadEvents() {
        //console.log(`[RB-CORE] :: Loading events ...`);
        addEventGroup('sdk');
        addEventGroup('connection');
        addEventGroup('im');
        addEventGroup('conversations');
        addEventGroup('telephony');
        addEventGroup('webRTC');
        addEventGroup('channels');
        addEventGroup('bubbles');
        addEventGroup('conferences');
        addEventGroup('fileStorage');
        addEventGroup('presence');
        console.log(`[RB-CORE] :: Loading events done`);
    }

    loadListeners() {
        Rainbow.ISDK.ONLOADED.subscribe( () => {
            console.log('[RB-CORE] :: Loading ReactRainbow SDK completed.');
            //this.setState({loaded: true});
            this.onLoaded.next(true);
            if (!this.state.initialised)
                rainbowSDK.initialize(this.props.config.credentials.appID, this.props.config.credentials.appSecret)
                    .then(()=>{
                        console.log(`[RB-CORE] :: ReactRainbow SDK rel ${rainbowSDK.version()} initialised with appID ${this.props.config.credentials.appID}.`);
                        //this.setState({initialised:true})
                        var sBrowser, sUsrAg = navigator.userAgent;
                        if (sUsrAg.indexOf('Chrome') > -1) {
                            sBrowser = 'Google Chrome or Chromium';
                            this.isChromeBrowser = true;
                            this.askMediaRightsForChrome();
                        } else {
                            /*this.getUserMediaRights().then(stream=>{this.updateMedaDevices()})
                              .catch(err=>{console.error(`[ALE-RB] :: did not get rights to use media`, err)});*/

                        }
                        console.log('[RB-CORE] :: ReactRainbow WebRTC is ' + (rainbowSDK.webRTC.canMakeAudioVideoCall() ? '' : 'NOT') + ' supported by this browser');
                        this.onInitialised.next(true);
                    });
        });
        Rainbow.ISDK.ONREADY.subscribe( () => {
            console.log(`[RB-CORE] :: ReactRainbow SDK rel ${rainbowSDK.version()} ready.`);
                this.onReady.next(true);
            });
        Rainbow.IConnection.ONSTARTED.subscribe( (data) => {
            //console.log('[RB-CORE] :: ReactRainbow ONSTARTED data', data);
            //this.setState({started: true})
            this.state.started = true;
            setTimeout(()=>{
                this.onStarted.next(true);
                //this.iConferences.initialise()
            }, 1000)
        });
        Rainbow.IConnection.ONSTOPPED.subscribe( () => {
            console.log('[RB-CORE] :: ReactRainbow stopped');
            //this.setState({started: false})
            this.state.started = false;
            setTimeout(()=>{this.onStarted.next(false);}, 1000)
        });
    }




    /***
     * Rainbow Authentication methods
     */
    login(loginEmail?: string, password?: string, sandbox = false) {
        return new Promise<any>((resolve, reject) => {
            if (this.sdk == null) {
                console.error('[RB-CORE] :: ReactRainbow Login failed, SDK not intialised');
                reject();
                return;
            }
            console.log(`[RB-CORE] :: ReactRainbow logging in ${sandbox ? 'Sandbox' : 'Official'}... `, loginEmail,password);
            
            if (sandbox) {
                console.log('[RB-CORE] :: ReactRainbow sandbox login');
                this.sdk.connection.signin(loginEmail, password).then((data) => {
                    console.log('[RB-CORE] :: ReactRainbow  Logged in sandbox for : ', data.account.loginEmail);
                    this.me = this.sdk.contacts.getConnectedUser();
                    //this._isloggedIn=true;
                    //this.token = this.getToken();
                    resolve(data);
                }).catch((err) => {
                    console.error('[ALE-RB] :: Error ReactRainbow login failed', err);
                    reject(err);
                });
            } else {
                console.log('[RB-CORE] :: ReactRainbow official login');
                this.sdk.connection.signinOnRainbowOfficial(loginEmail, password).then((data) => {
                    console.log('[RB-CORE] :: ReactRainbow  Logged in for : ', data.account.loginEmail);
                    this.me = this.sdk.contacts.getConnectedUser();
                    //this._isloggedIn = true;
                    //this.token = this.getToken();
                    resolve(data);
                }).catch((err) => {
                    console.error('[RB-CORE] :: Error ReactRainbow login failed', err);
                    reject(err);
                });
            }
        });
    }

    /*loginWithAPI(loginEmail: string, password: string) {
      return new Promise<any>((resolve, reject) => {
        this.rest.authentication.login(loginEmail, password, this.config.secret, this.config.key).then(response => {
          console.log('[ALE-RB] :: Rest login', response);
          this.token = response.token;
          resolve(response);
        }).catch(err => reject(err));
      });
    }*/

    loginWithToken(token: string, sandbox: boolean = false) {
        return new Promise<any>((resolve, reject) => {
            if (this.sdk == null) {
                console.error('[ALE-RB] :: ReactRainbow Login failed, SDK not intialised');
                reject();
            }
            //console.log('[ALE-RB] :: NgxRainbow  logging in with token ... ');
            if (sandbox ) {
                //this.config.sandbox = true;
                this.sdk.connection.signinSandBoxWithToken(token).then((data) => {
                    //console.log('[ALE-RB] :: NgxRainbow  Logged in with token for : ', data.account.loginEmail);
                    //this.rest.isSandbox = true;
                    this.me = this.sdk.contacts.getConnectedUser();
                    //this._isloggedIn = true;
                    resolve(data);
                }).catch((err) => {
                    console.error('[ALE-RB] :: Error ReactRainbow sandbox login with token failed', err);
                    reject(err);
                });
            } else {
                this.sdk.connection.signinOnRainbowOfficialWithToken(token).then((data) => {
                    //console.log('[ALE-RB] :: NgxRainbow  Logged in with token for : ', data.account.loginEmail);
                    this.me = this.sdk.contacts.getConnectedUser();
                    //this._isloggedIn = true;
                    //this.rest.isSandbox = false;
                    resolve(data);
                }).catch((err) => {
                    console.error('[ALE-RB] :: Error ReactRainbow login with token failed', err);
                    reject(err);
                });
            }
        });
    }
    logout() {
        console.log(`[RB-CORE] :: logout`)
        return new Promise((resolve, reject) => {
            if (this.sdk == null) {
                console.error('[RB-CORE] :: ReactRainbow  Logout failed, SDK not intialised');
                reject();
                return;
            }
            console.log('[RB-CORE] :: ReactRainbow logging out ... ');

            //this._isloggedIn=false;
            this.sdk.connection.signout().then((data) => {
                console.log('[RB-CORE] :: ReactRainbow logged out', data);
                resolve(data);
            }).catch((err) => {
                console.error('[RB-CORE] :: Error ReactRainbow log-out failed', err);
                reject(err);
            });
        });
    }

    /***
     * Rainbow IM and Channel functions
     */
    async im(contact, msg, alternativeMsg=''):Promise<Rainbow.Message> {
        //console.log('Contact:', this.sdk.contacts.getContactByJID(contact));
        let conversation = await this.sdk.conversations.getConversationByContactId(typeof contact == 'string'?contact:contact.id);
        //console.log('[ALE-RB] :: NgxRainbow Sending IM, get conversation: ', conversation);
        if (conversation) {
            return this.sdk.im.sendMessageToConversation(conversation.id, msg, 'text', alternativeMsg);
        } else {
            //console.log('[ALE-RB] :: NgxRainbow Error retrieving conversation');
            return null;
        }
    }
}

export namespace Rainbow { //WEB 2.0.0-LTS (JUNE 2021)

    export class Config {
        verboseLog?: boolean = false // Turn on/off all the logs linked to the SDK
        measurePerformance?: boolean = false // Add performance measurement points while loading the SDK
        unifiedPlan?: boolean = false // Use WebRTC unified plan
        videoQuality?: '1080p' = "1080p" // Chose default broadcasted video quality
        credentials: {
            appID?: string
            appSecret?: string
            host?: "openrainbow.com"
        } = {}
        constructor(cfg:Config) {
            Object.assign(this,cfg)
            //console.log(`[ReactRainbow] :: Config is `,cfg, this);
        }
    }
    export interface SDK { //WEB 2.0.0-LTS (JUNE 2021)
        admin: AdminService
        bubbles: BubblesService
        callsLog: CallsLogService
        capabilities: CapabilitiesService
        channels: ChannelsService
        conferences: ConferencesService
        config: ConfigService
        connection: ConnectionService
        contacts: ContactsService
        conversations: ConversationsService
        favorites: FavoritesService
        fileStorage: FileStorageService
        groups: GroupsService
        im: ImService
        presence: PresenceService
        telephony: TelephonyService
        userProfile: UserProfileService
        webRTC: WebRTCService

        load()
        start()
        version(): string
        mode(): string
        hasBeenLaunchedFromConfig(boolFromConfig): boolean
        //useAngularEvents(boolUseAngularEvents)
        initialize(strApplicationID, strApplicationSecret): Promise<any>
        RAINBOW_ONREADY, RAINBOW_ONLOADED
    }

    export class ISDK {
        static get ONLOADED(): ReplaySubject<any> {return  subscribe(rainbowSDK.RAINBOW_ONLOADED)};
        static get ONREADY(): ReplaySubject<any> {return  subscribe(rainbowSDK.RAINBOW_ONREADY)};
    }




    export interface AdminService {
        createEssentialCompany(strName, strCountry) : Promise<any>
        createUserForCompany(strLogin, strFirstName, strLastName, strPassword, companyId, tags) : Promise<any>
        _createUserForCompanyDeprecated(strLogin, strFirstName, strLastName, strPassword, companyId, tags) : Promise<any>
        removeUserFromCompany(user) : Promise<any>
        fetchUsersByPattern(searchPattern, page, pageSize) : Promise<any>
        fetchUsersByTag(tag, page, pageSize) : Promise<any>
        updateUserTags(user, tags) : Promise<any>
        setVisibilityForCompany(company, visibleByCompany) : Promise<any>
        createCompany(strName, strCountry, strState) : Promise<any>
        removeCompany(companyId) : Promise<any>
        _removeCompanyDeprecated(company) : Promise<any>
    }

    //region Bubble code
    export interface BubblesService {
        createBubble(strName, strDescription, boolWithHistory, disableNotifications, urlAvatar, autoAcceptInvitation) : Promise<any>
        deleteBubble(bubble) : Promise<any>
        deleteAllBubbles() : Promise<any>
        inviteContactToBubble(contactId, bubble, boolAsModerator, boolWithInvitation) : Promise<any>
        removeContactFromBubble(contactId, bubble) : Promise<any>
        promoteContactToModerator(contactId, bubble) : Promise<any>
        demoteContactFromModerator(contactId, bubble) : Promise<any>
        changeOwner(contactId, bubble) : Promise<any>
        acceptInvitationToJoinBubble(bubble) : Promise<any>
        declineInvitationToJoinBubble(bubble) : Promise<any>
        leaveBubble(bubble) : Promise<any>
        closeBubble(bubble) : Promise<any>
        isBubbleArchived(bubble) : boolean
        getAllBubbles() : Array<any>
        getAllOwnedBubbles() : Array<any>
        getAllPendingBubbles() : Array<any>
        getAllInactiveBubbles() : Array<any>
        getBubbleById(strBubbleId) : Array<any>
        updateNameForBubble(strName, bubble) : Promise<any>
        updateDescriptionForBubble(strDescription, bubble) : Promise<any>
        updateAvatarForBubble(urlAvatar, bubble) : Promise<any>
        deleteAvatarFromBubble(bubble) : Promise<any>
        updateCustomDataForBubble(customData, bubble) : Promise<any>
        deleteCustomDataForBubble(bubble) : Promise<any>
        getBubbleFromServer(bubble) : Promise<any>
        createPublicUrl(bubbleId) : Promise<any>
        RAINBOW_ONBUBBLEINVITATIONTOJOINRECEIVED, RAINBOW_ONBUBBLEUPDATED, RAINBOW_ONBUBBLEAVATARUPDATED, RAINBOW_ONBUBBLEDEACTIVATED
    }
    export class Bubble {
        dbId?
        jid?
        name?
        nameForLogs?
        desc?
        users?
        history?
        customData?
        ownerContact?
        organizers?: {
            contact: any,
            status: string,
            date: Date
            privilege: string
        }[]
        members?: {
            contact: any,
            status: string,
            date: Date
            privilege: string
        }[]
        avatarContacts?
        owner?
        isModerator?
        confEndpoints?: any[]
        conference?
        status?:  Bubble.Status//'accepted'|'unsubscribed'|'invited'
    }
    export namespace Bubble {
        export enum Status {ACTIVE='accepted', ARCHIVED='unsubscribed', INVITED='invited'}
    }

    export class IBubbles {
        static get ONUPDATED(): ReplaySubject<Bubble> {return  subscribe(rainbowSDK.bubbles.RAINBOW_ONBUBBLEUPDATED)};
        static getAll(): IBubble[] { return rainbowSDK.bubbles.getAllBubbles().map(b=>{return new IBubble(b)})}
        static getFromServer(bubble): Promise<IBubble> {
            return new Promise<IBubble>((resolve, reject)=>{
                if (!bubble || !bubble.dbId) reject(false);
                rainbowSDK.bubbles.getBubbleFromServer(bubble2=>{
                    resolve(new IBubble(bubble2))
                }).catch(e=>reject(false))
            })
        }
    }

    export class IBubble extends Bubble{
        static async create(strName, strDescription='', boolWithHistory=false, disableNotifications=false, urlAvatar=null, autoAcceptInvitation=false) {
            return rainbowSDK.bubbles.createBubble(strName, strDescription, boolWithHistory, disableNotifications, urlAvatar, autoAcceptInvitation)
        }
        static async delete(bubble: IBubble) {return rainbowSDK.bubbles.deleteBubble(bubble);}
        static async leave(bubble: IBubble) {return rainbowSDK.bubbles.leaveBubble(bubble);}
        static async join(bubble:IBubble) {return rainbowSDK.bubbles.acceptInvitationToJoinBubble(bubble)}
        static async joinConference(bubble:IBubble) {return rainbowSDK.conferences.startOrJoinWebConference(bubble)}
        static getAllMessages(bubble:IBubble) {
            let messages = [];
            try {
                messages = rainbowSDK.conversations.getConversationByBubbleId(bubble.dbId).messages
            } catch (e) {
                console.error(`[RB-CORE] :: Error with getConversation, no messages retrieved`)
                messages = []
            }
            return messages;
        }
        static async stopConference() {return rainbowSDK.conferences.stopWebConference()}

        constructor(strNameOrBubble?: string|Bubble, strDescription='', boolWithHistory=false,
                    disableNotifications=false, urlAvatar=null, autoAcceptInvitation=false) {
            super();
            if (strNameOrBubble) {
                if (typeof strNameOrBubble == 'string') {
                    IBubble.create(strNameOrBubble, strDescription, boolWithHistory, disableNotifications, urlAvatar, autoAcceptInvitation)
                        .then(bubble=>{Object.assign(this, bubble);})
                        .catch(err=>{console.error(`Could not create bubble`, err)})
                } else {
                    Object.assign(this, strNameOrBubble);
                    if (this.dbId) {
                        //this.iConversation = this.conversation//rainbowSDK.conversations.getConversationByBubbleId(this.dbId);
                        this.getMessages(20);
                    } else {
                        console.error(`[RB-CORE] :: this bubble is not valid, dbId not set`, JSON.stringify(strNameOrBubble))
                    }
                }
                /*document.addEventListener('RAINBOW_ONBUBBLEUPDATED', (data:any) => {
                  console.log(`[ALE-RB] :: NgxRainbow Event ${'RAINBOW_ONBUBBLEUPDATED'.toUpperCase()} triggered`);
                  this.onUpdated.next((data.detail) ? data.detail : {});
                });*/
            }
        }
        delete() {IBubble.delete(this)}
        leave() {IBubble.leave(this)}
        join() {IBubble.join(this)}
        joinConference() {IBubble.joinConference(this)}
        stopConference() {IBubble.stopConference()}
        private iConversation: Conversation;
        get hasConversation(): boolean {
            try {
                this.iConversation = rainbowSDK.conversations.getConversationByBubbleId(this.dbId)
            }catch (e) {
                //console.error(`[RB-CORE] :: Error with getConversation for ${this.name}`)
                return false
            }
            return true
        }
        get conversation(): Conversation {
            //console.log(`conversation:`,this, rainbowSDK.conversations.getAllConversations());
            try {
                this.iConversation = rainbowSDK.conversations.getConversationByBubbleId(this.dbId)
            }catch (e) {
                console.error(`[RB-CORE] :: Error with getConversation for ${this.name}`)
                this.iConversation = null
            }
            return this.iConversation
        }
        get moreMessages(): boolean {return this.hasConversation?this.iConversation.historyComplete:false}
        async getMessages(num=10): Promise<Rainbow.Message[]> {
            console.log(`[RB-CORE] :: getMessages request for ${num} messages`);
            if (!this.hasConversation) return Promise.resolve(this.messages)
            if (this.moreMessages || this.messages.length==0) {
                let data = await rainbowSDK.im.getMessagesFromConversation(this.conversation.id, num);
                console.log(`[RB-CORE] :: ${this.messages.length}/${data.messages.length} messages from conversation, ${num}`, data);
                setTimeout(()=>{
                    this.onUpdatedMessages.next(this.messages)
                    return Promise.resolve(this.messages);
                }, 2000)
            } else {
                console.log(`[RB-CORE] :: getMessages returning bubble messages, ${num}`);
                return Promise.resolve(this.messages);
            }
        }
        get messages():Message[] {return IBubble.getAllMessages(this)}
        withJid(jid): this {
            let bubble = rainbowSDK.bubbles.getAllBubbles().find((b:any)=>b.jid==jid)
            if (bubble) {
                Object.assign(this, bubble);
                //console.warn(`[RB-CORE] :: getting conversation`, this)
                //this.iConversation = this.conversation; //rainbowSDK.conversations.getConversationByBubbleId(this.dbId);
                //console.warn(`[RB-CORE] :: iconversation`, this.iConversation)
                this.getMessages(20);
            } else {
                console.error(`[RB-CORE] :: withJid bubble not found`)
            }
            return this;
        }
        withId(id): this {
            let bubble = rainbowSDK.bubbles.getAllBubbles().find((b:any)=>b.dbId==id)
            console.log(`[RB-CORE] :: IBubble.withId`, id, bubble)
            if (bubble) {
                Object.assign(this, bubble);
                //this.iConversation = this.conversation//rainbowSDK.conversations.getConversationByBubbleId(this.dbId);
                this.getMessages(20);
            }else {
                console.error(`[RB-CORE] :: withId bubble not found`)
            }
            return this;
        }
        async fromServer(bubble): Promise<this> {
            if (!bubble) return this;
            let bubble2 = await rainbowSDK.bubbles.getBubbleFromServer(bubble)
            console.log(`[RB-CORE] :: IBubble.withId`, bubble2)
            if (bubble2) {
                Object.assign(this, bubble2);
                //this.iConversation = this.conversation//rainbowSDK.conversations.getConversationByBubbleId(this.dbId);
                this.getMessages(20);
            }else {
                console.error(`[RB-CORE] :: fromServer bubble not found`)
            }
            return this;
        }

        get data(): Rainbow.IBubble.AleBubbleCustomDataObject {return this.customData}
        set data(value:Rainbow.IBubble.AleBubbleCustomDataObject) {
            if (!value) {
                rainbowSDK.bubbles.deleteCustomDataForBubble(this);
                return;
            }
            rainbowSDK.bubbles.updateCustomDataForBubble(value,this).catch(err=>{
                console.error(`[RB-CORE] :: Could not save customData`, err, this);
                value['error']=err;
            })
        }
        onUpdatedMessages: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
        onBubbleMessageReceived: ReplaySubject<Message> = new ReplaySubject<Rainbow.Message>();
    }
    export namespace IBubble {
        export interface AleBubbleCustomDataObject {
            appType: AleBubbleCustomDataObject.AppType
            data: any
        }
        export namespace AleBubbleCustomDataObject {
            export enum AppType {BUBBLECAST='bubblecast', TEMICONTROL='temicontrol', NONE='none'}
        }
    }
    //endregion

    export interface CallsLogService {
        getAll() : Promise<any>
        getMissedCallLogCounter() : number
        getMissedCallLogNumber() : number
        deleteOneCallLog(id) : boolean
        deleteCallLogsForContact(jid) : boolean
        deleteAllCallLogs() : void
        markCallLogAsRead(id) : void
        markAllCallsLogsAsRead() : void
        isInitialized() : boolean
        RAINBOW_ONCALLLOGUPDATED, RAINBOW_ONCALLLOGACKUPDATED
    }
    export interface CapabilitiesService {
        getAll() : Array<any>
        getUserSubscription() : string
        isFeatureEnabled(featureString) : boolean
        hasS4BExtensionEnabled() : boolean
        hasOutlookExtensionEnabled() : boolean
        hasCalendarPresenceEnabled() : boolean
        hasTelephonyBasicCallEnabled() : boolean
        hasTelephonySecondCallEnabled() : boolean
        hasTelephonyTransferCallEnabled() : boolean
        hasTelephonyConferenceCallEnabled() : boolean
        hasTelephonyDeflectCallEnabled() : boolean
        hasTelephonyForwardCallEnabled() : boolean
        hasTelephonyPhoneBookEnabled() : boolean
        hasTelephonyVoiceMailEnabled() : boolean
        hasWebRTCAudioMobileEnabled() : boolean
        hasWebRTCVideoMobileEnabled() : boolean
        hasBridgeConferenceEnabled() : boolean
        hasBridgeConferenceRecordEnabled() : boolean
        hasBridgeConferenceDialOutEnabled() : boolean
        maxParticipantsPerBubbleAllowed() : number
        maxParticipantsPerBridgeConferenceAllowed() : number
        maxFileStorageQuotaAllowed() : number
    }

    //region Channel code
    export interface ChannelsService {
        fetchMyChannels() : Promise<any>
        createPublicChannel(channelName, channelTopic, category) : Promise<any>
        createClosedChannel(channelName, channelTopic?, category?) : Promise<any>
        deleteChannel(id) : Promise<any>
        fetchChannelsByName(name?) : Promise<any>
        fetchChannelsByTopic(topic?) : Promise<any>
        fetchChannelsByCategory(category?) : Promise<any>
        fetchChannel(id) : Promise<any>
        createItem(channelId, message, title?, url?, itemType?) : Promise<any>
        subscribeToChannelById(id) : Promise<any>
        _subscribeToChannel(channel) : Promise<any>
        unsubscribeFromChannelById(id) : Promise<any>
        _unsubscribeFromChannel(channel) : Promise<any>
        updateChannel(id, channelTopic?, visibility?, maxItems?, maxPayloadSize?, channelName?, category?) : Promise<any>
        updateChannelVisibility(id, visibility) : Promise<any>
        updateChannelVisibilityToPublic(id) : Promise<any>
        updateChannelVisibilityToClosed(id) : Promise<any>
        updateChannelName(id, channelName) : Promise<any>
        updateChannelTopic(id, channelTopic) : Promise<any>
        updateChannelCategory(id, category) : Promise<any>
        updateChannelAvatar(id, urlAvatar) : Promise<any>
        deleteChannelAvatar(id) : Promise<any>
        fetchUsers(id, options?) : Promise<any>
        deleteAllUsersFromChannel(id) : Promise<any>
        updateChannelUsers(id, users, userType?) : Promise<any>
        addMembersToChannel(id, users) : Promise<any>
        addPublishersToChannel(id, users) : Promise<any>
        addOwnersToChannel(id, users) : Promise<any>
        deleteUsersFromChannel(id, users) : Promise<any>
        fetchItems(id, maxMessages?, beforeDate?, afterDate?) : Promise<any>
        deleteItem(id, itemId) : Promise<any>
        RAINBOW_ONCHANNELMESSAGERECEIVED, RAINBOW_ONCHANNELUPDATED, RAINBOW_ONCHANNELDELETED, RAINBOW_ONCHANNELRETRACTMESSAGERECEIVED, RAINBOW_ONCHANNELAVATARUPDATED, RAINBOW_ONCHANNELUSERSUBSCRIBED, RAINBOW_ONCHANNELUSERUNSUBSCRIBED
    }
    export class Channel {
        name
        id
        visibility
        topic
        creatorId
        companyId
        creationDate
        type
        users_count
        subscribers_count
        category
        mode
    }
    export class ChannelMessage {
        channelId: string
        appreciations: { happy: number, fantastic: number, applause: number, like: number, doubt: number }
        type: string
        entry: {
            attachments?: []
            images?: { id:string }
            message?: string
            title?: string
            type?: string
            url?: string
            video?: string
            from?: string
        }
        from: string
        id: string
        modified: boolean
        timestamp: any
    }
    export class IChannels {
        static get ONUPDATED(): ReplaySubject<any> {return subscribe(rainbowSDK.channels.RAINBOW_ONCHANNELUPDATED)};
    }


    export class IChannel extends Channel{

        static async create(strName, strDescription=''):Promise<IChannel> {
            return new IChannel(await rainbowSDK.channels.createClosedChannel(strName, strDescription))
        }
        static async delete(item: IChannel) {return rainbowSDK.channels.deleteChannel(item);}
        static async leave(item: IChannel) {return rainbowSDK.channels.unsubscribeFromChannelById(item.id);}
        static async join(item: IChannel) {return rainbowSDK.channels.subscribeToChannelById(item.id)}
        static async getAll(): Promise<Channel[]> { return rainbowSDK.channels.fetchMyChannels()}

        constructor(strNameOrChannel?: string|Channel, strDescription='') {
            super();
            if (strNameOrChannel) {
                if (typeof strNameOrChannel == 'string') {
                    IChannel.create(strNameOrChannel, strDescription)
                        .then(channel=>Object.assign(this, channel))
                        .catch(err=>{console.error(`Could not create channel`, err)})
                } else {
                    Object.assign(this, strNameOrChannel)
                }
            }
        }
        delete() {IChannel.delete(this)}
        leave() {IChannel.leave(this)}
        join() {IChannel.join(this)}

        async fetchWithId(id):Promise<this> {
            let channel = await rainbowSDK.channels.fetchChannel(id);
            if (channel) Object.assign(this, channel)
            return this;
        }
        async getItems(num=100):Promise<Rainbow.ChannelMessage[]> {
            return await rainbowSDK.channels.fetchItems(this.id, num);
        }
        async addOwners(contacts: Contact[]) {
            return rainbowSDK.channels.addOwnersToChannel(this.id,contacts)
        }
    }
    //endregion

    //region conferences code
    export interface ConferencesService {
        isWebConferenceAllowed() :  boolean
        hasActiveWebConferenceSession() :  boolean
        getWebConferenceBubble() :  Rainbow.Bubble
        setActiveSpeakerValue(value) :  boolean
        startOrJoinWebConference(bubble) :  Promise<any>
        stopWebConference() :  Promise<any>
        muteConferenceAudio(conferenceSession) :  Promise<any>
        unmuteConferenceAudio(conferenceSession) :  Promise<any>
        muteConferenceParticipant(conferenceSession, participantId) :  Promise<any>
        unmuteConferenceParticipant(conferenceSession, participantId) :  Promise<any>
        muteAllConferenceParticipants(conferenceSession) :  Promise<any>
        unmuteAllConferenceParticipants(conferenceSession) :  Promise<any>
        addVideoToConference(conferenceSession) :  object
        addSharingToConference(conferenceSession) :  object
        removeVideoFromConference(conferenceSession) :  object
        removeSharingFromConferenceSession(conferenceSession) :  object
        showLocalVideo(conferenceSession) :  object
        showRemoteVideo(conferenceSession) :  object
        getConferenceSessionById(conferenceId) :  object
        updateGalleryPublisher(conferenceSession, galleryElementId, newPublisher) :  Promise<any>
        removeGalleryPublisher(conferenceSession, galleryElementId) :  object
        setVideoGalleryElementsNumber(elementsNumber) :  number
        removePipVideo() :  boolean
        updateMainVideoSession(conferenceId, sessionId) :  object
        disconnectParticipant(conferenceId, participantId, mediaType) :  Promise<any>
        getListOfRemoteVideoPublishers(conferenceSession) :  object
        delegateConferenceToParticipant(conferenceId, participantId) :  Promise<any>
        toggleAutoStreamQuality(value) :  boolean
        getElementStreamQuality(elementId) :  object
        RAINBOW_ONWEBCONFERENCEUPDATED, RAINBOW_ONWEBRTCMEDIAERROROCCURED, RAINBOW_ONBUBBLECONFERENCESTARTED, RAINBOW_ONBUBBLECONFERENCETALKERACTIVE, RAINBOW_ONBUBBLESHARINGCONFERENCESTARTED, RAINBOW_ONWEBCONFERENCEDELEGATED
    }

    export interface ConferenceSession {
        active: false
        callId: null
        conferenceLayoutSize: {}
        conferenceStats: null
        conferenceView: "gallery_view"
        control: {activeControl: false, hasRemoteControlling: false, controller: null, controlled: null}
        currentRecordingState: ""
        dimLocalSharingScreen: true
        duration: 0
        durationTimer: null
        externalWindowRef: null
        hasLocalVideo: false
        haveJoined: false
        id: string
        isInExternalWindow: false
        isLeaderAlreadyConnected: false
        isMyConference: false
        isOnlySharingWindow: false
        jingleJid: null
        lastTalkerId: ""
        localSharingSessionId: null
        localStreams: []
        localVideoSessionId: null
        lock: false
        networkQuality: 0
        noMicrophone: false
        participants: []
        publishers: []
        record: {isRecording: false, recordingIsPaused: false}
        recordingStarted: false
        sessions: {}
        status: ConferenceSession.STATUS
        talkerActive: undefined
        talkers: undefined
        talkingTimeStats: {}
        type: "webrtc"
    }
    export namespace ConferenceSession {
        export enum STATUS  {CONNECTED='connected', ENDED='ended', RINGING='ringing', UNKNOWN='unknown'}
    }
    export class IConferences {
        private items: {[key:string]: IConference} = {};
        add(conf:IConference): boolean {
            if (conf.bubble.hasOwnProperty('dbId') && conf.bubble.dbId) {
                this.items[conf.bubble.dbId] = conf;
                return true
            }
            return false;
        }
        addAllBubbles(): number[] {
            let failed=[];
            IBubbles.getAll().forEach((bubble, idx)=>{
                if (!this.add(new IConference(bubble))) failed.push(idx)
            })
            return failed;
        }
        static get all(): IConference[] {return IBubbles.getAll().map(b=>{return new IConference(b)})}
        static get hasJoined(): boolean {return rainbowSDK.conferences.hasActiveWebConferenceSession()}
        static async join(item: IBubble):Promise<Bubble> {return rainbowSDK.conferences.startOrJoinWebConference(item)}
        static async leave():Promise<any> {return rainbowSDK.conferences.stopWebConference()}
        static get ONUPDATED(): ReplaySubject<ConferenceSession> {return subscribe(rainbowSDK.conferences.RAINBOW_ONWEBCONFERENCEUPDATED)};
        static get ONSTARTED(): ReplaySubject<Bubble> {return subscribe(rainbowSDK.conferences.RAINBOW_ONBUBBLECONFERENCESTARTED)};

        getWithBubble(iBubble:IBubble): IConference {return this.items[iBubble.dbId]}
        get joinedConference(): IConference {return (IConferences.hasJoined?this.items[rainbowSDK.conferences.getWebConferenceBubble().dbId]:null)}
        onNewAdded: Subject<IConference> = new Subject<IConference>()
        onActiveConferenceStatusChange: Subject<ConferenceSession.STATUS> = new Subject<ConferenceSession.STATUS>()
        private _selectedConferenceIdx: string="";
        get selectedConferenceIdx(): string {return this._selectedConferenceIdx}
        set selectedConferenceIdx(dbId) {
            if (this._selectedConferenceIdx!=dbId) {
                this._selectedConferenceIdx = dbId;
                IBubbles.getFromServer(this.items[dbId].bubble).then(bubble=>{
                    this.items[dbId].bubble = bubble
                    this.onSelectedConferenceChanged.next(this.items[dbId])
                }).catch(e=>{
                    this.onSelectedConferenceChanged.next(this.items[dbId])
                })

            }
        }
        onSelectedConferenceChanged: Subject<IConference> = new Subject<IConference>()
        onBubbleUpdated:Subject<IBubble> = new Subject<IBubble>()
        joinedSession: ConferenceSession;

        get bubbleList(): IBubble[] {
            return Object.values(this.items).map(c=>c.bubble);
        }

        constructor() {}
        initialise() {
            Object.assign(this, IConferences.all)
            IConferences.ONSTARTED.subscribe(bubble=>{
                console.log(`[RB-CORE] :: Conference '${bubble.name}' started: ${bubble.confEndpoints.toString()}`, bubble)
            })
            IBubbles.ONUPDATED.subscribe((bubble: Bubble)=>{
                if (!bubble || !bubble.hasOwnProperty('dbId')) return;
                this.add(new IConference(bubble));
                this.onBubbleUpdated.next(this.items[bubble.dbId].bubble)
            })
            IConferences.ONUPDATED.subscribe(session=>{
                console.log(`[RB-CORE] :: IConference ${session.id} update : ${session.status}`, this.bubbleList)
                this.bubbleList.forEach(bubble=>{
                    if (bubble.confEndpoints.map(e=>e.confEndpointId).includes(session.id)) {
                        console.log(`[RB-CORE] :: IConference session found in bubble ${bubble.name}`)
                        new IBubble().fromServer(bubble).then(bubble=>{
                            this.add(new IConference(bubble));
                            this.onBubbleUpdated.next(bubble)
                            if (this.joinedSession && this.joinedSession.id==session.id) {
                                if (this.joinedSession.status!=session.status) {
                                    this.onActiveConferenceStatusChange.next(session.status)
                                    console.log(`[RB-CORE] :: onActiveConferenceStatusChange send for bubble ${bubble.name}`)
                                }
                            }
                            this.joinedSession = session;
                        });
                    }
                })
            })

            Rainbow.IConversations.ONMISSEDCOUNTERCHANGED.subscribe(data => {
                if (!rainbowService.state.started) return;
                if (data.missedIMDetails.length > 0) {
                    if (data.missedIMDetails[0].room) {
                        new IBubble().fromServer(data.missedIMDetails[0].room).then(bubble=>{
                            console.log(`[RB-CORE] :: IConferences ONMISSEDCOUNTERCHANGED: new bubble '${bubble.name}' added`)
                            this.onBubbleUpdated.next(bubble)
                        });
                    }
                }
            })
        }
    }
    export class IConference {
        session: ConferenceSession;
        bubble: IBubble;
        get isValid() {return (this.bubble && this.bubble.hasOwnProperty('dbId'))}
        //onStarted: ReplaySubject<boolean> = new ReplaySubject<boolean>()
        //onUpdated: ReplaySubject<boolean> = new ReplaySubject<boolean>()
        //onStatusChange: ReplaySubject<any> = new ReplaySubject<any>()
        //static get isStarted(): boolean {return rainbowSDK.conferences.hasActiveWebConferenceSession()}

        constructor(bubble:Bubble) {
            //console.log(`[RB-CORE] :: new IConference`, bubble)
            this.bubble = new IBubble(bubble);
        }
        get isJoined(): boolean {return (this.isValid?RainbowSDK.conferences.getWebConferenceBubble().dbId==this.bubble.dbId: false)}
        get isLive(): boolean {return this.isValid?this.bubble.confEndpoints.length>0: false}
        get status(): Rainbow.ConferenceSession.STATUS {return this.session?this.session.status:Rainbow.ConferenceSession.STATUS.UNKNOWN}
        join() {
            IConferences.join(this.bubble)
        }
        leave() {
            IConferences.leave()
        }
    }
    //endregion

    export interface ConfigService {
        setSimulcast(value) : boolean
        setVideoQuality(value) : string
        setVerboseLog(value) : boolean
        useNewModels(value) : boolean
        setDisplayOrder(value) : string
        setCustomLogger(logger) : void
        getCurrentConfig() : object
    }

    //region Connection Code
    export interface ConnectionService {
        signin(strLogin, strPassword) : Promise<any>
        signinOnRainbowOfficial(strLogin, strPassword) : Promise<any>
        signinOnRainbowBeta(strLogin, strPassword) : Promise<any>
        signinOnRainbowCPaasPreProd(strLogin, strPassword) : Promise<any>
        signinOnRainbowDev(strLogin, strPassword) : Promise<any>
        signinOnRainbowSandboxChina(strLogin, strPassword) : Promise<any>
        signinOnRainbowChina(strLogin, strPassword) : Promise<any>
        signinOnRainbowHosted(strLogin, strPassword, strHost) : Promise<any>
        signinSandBoxWithToken(strToken) : Promise<any>
        signinOnRainbowOfficialWithToken(strToken) : Promise<any>
        signinOnRainbowBetaWithToken(strToken) : Promise<any>
        signinOnRainbowDevWithToken(strToken) : Promise<any>
        signinOnRainbowHostedWithToken(strToken, strHost) : Promise<any>
        setRenewedToken(strToken) : void
        signinOnRainbowWithOICD(strToken) : any
        styleSignInWithRainbowButton(color, size) : boolean
        signout() : Promise<any>
        updateUserPassword(oldPassword, newPassword) : Promise<any>
        getState() : string
        getToken() : string
        refreshToken() : Promise<any>
        RAINBOW_ONCONNECTIONSTATECHANGED, RAINBOW_ONSTARTED, RAINBOW_ONSTOPPED, RAINBOW_ONUSERTOKENRENEW, RAINBOW_ONUSERTOKENRENEWFAILED, RAINBOW_ONUSERTOKENWILLEXPIRE, RAINBOW_ONAPPTOKENRENEW, RAINBOW_ONAPPTOKENRENEWFAILED
    }
    export class IConnection {
        static get ONSTATECHANGED(): ReplaySubject<IConnection.State> {return  subscribe(rainbowSDK.connection.RAINBOW_ONCONNECTIONSTATECHANGED)};
        static get ONSTARTED(): ReplaySubject<any> {return  subscribe(rainbowSDK.connection.RAINBOW_ONSTARTED)};
        static get ONSTOPPED(): ReplaySubject<any> {return  subscribe(rainbowSDK.connection.RAINBOW_ONSTOPPED)};
    }
    export namespace IConnection {
        export enum State {INPROGRESS = 'inProgress', CONNECTED = 'connected', DISCONNECTED = 'disconnected', UNLOGGED='unlogged'}
    }
    //endregion

    //region Contact Code
    export class Contact {
    id : string
    status : string
    loginEmail : string
    lastname : string
    firstname : string
    fullname : string
    nickname : string
    initials : string
    title : string
    jobTitle : string
    language : string
    country : string
    tags : Array<any>
    internalPhoneNumber : string
    professionalPhoneNumber : string
    personalPhoneNumber : string
    personalMobilePhoneNumber : string
    voicemailNumber : string
    roles : Array<any>
    customData : object
    pbxId : string
    displayOrder : string
    avatar : string
    userInfo1 : object
    userInfo2 : object
    }

    export class IContact extends Contact {
        activeCall: Rainbow.Call;
        constructor(contact: Rainbow.Contact) {
            super();
            Object.assign(this, contact)
        }

        call(withVideo:boolean = false): this {
            console.log(`[RB-CORE] :: Setting up call with ${this['name'].value}`)
            let rs= new ReplaySubject();
            if (rainbowService.webRTC.callStatus!==Rainbow.Call.Status.UNKNOWN && rainbowService.webRTC.callStatus!==undefined) {
                console.warn(`[RB-CORE] :: a call is already in progress`, rainbowService.webRTC.callStatus)
                return this;
            }

            if (!withVideo) {
                rainbowService.sdk.webRTC.callInAudio(this['dbId']).then(status => {
                    if (status == 'OK') {
                        let s = rainbowService.webRTC.onCallActive
                        .subscribe(status=>{
                            this.activeCall=rainbowService.webRTC.call;
                            if (status==false) {
                                s.unsubscribe()
                                this.activeCall=null;
                            }
                        })
                    } else console.error(`[RB-CORE] :: No callInAudio possible`)
                })
            } else {
                rainbowService.sdk.webRTC.callInVideo(this['dbId']).then(status => {
                    if (status == 'OK') {

                    } else console.error(`[RB-CORE] :: No callInVideo possible`)
                })
            }
            return this;
        }
    }

    export interface ContactsService {
        _getContactByJID(strJID) : object
        getContactById(contactId) : Promise<any>
        getNetworkContacts() : Promise<Rainbow.Contact[]>
        getAll() : Array<any>
        getConnectedUser() : Contact
        getConnectedUserPhone() : any
        searchByName(strName, limit, addRosters, companyId, excludeCompanyId) : Promise<any>
        searchPhonebook(strPattern) : Promise<any>
        searchOffice365(strPattern) : Promise<any>
        searchCompanyDirectory(strPattern, companyId) : Promise<any>
        searchByLogin(strLogin) : Promise<any>
        _searchByJid(strJid) : Promise<any>
        searchById(strId) : Promise<any>
        acceptInvitation(invitationId) : Promise<any>
        declineInvitation(invitationId) : Promise<any>
        addToNetwork(contactId) : Promise<any>
        sendInvitationByEmail(email, customMessage) : Promise<any>
        removeFromNetwork(contactId) : Promise<any>
        getInvitationById(invitationId) : object
        getInvitationsReceived() : Array<any>
        cancelInvitation(invitationId) : Promise<any>
        getInvitationsSent() : Array<any>
        updateUserCustomData(customData) : Promise<any>
        RAINBOW_ONCONTACTINFORMATIONCHANGED, RAINBOW_ONINFORMATIONCHANGED, RAINBOW_ONCONTACTINVITATIONRECEIVED, RAINBOW_ONCONTACTINVITATIONNUMBERCHANGED
    }
    //endregion

    //region Conversation block
    export class Conversation {
        id : string
        type : number
        contactId : string
        bubbleId : string
        name : string
        creationDate : Date
        lastModificiationDate : Date
        isFavorite : boolean
        messages : any
        lastMessage : string
        historyComplete: any //not documented??
    }
    export interface ConversationsService {
        getAllConversations() : Array<Conversation>
        getCallById(strCallId) : object
        getConversationById(conversationId) : Conversation
        _getConversationByIdDeprecated(conversationId) : object
        getConversationByBubbleId(bubbleId) : Conversation
        _openConversationForContact(contact) : Promise<any>
        getConversationByContactId(contactId) : Promise<Conversation>
        _openConversationForBubble(bubble) : Promise<any>
        closeConversation(conversationId)
        _closeConversationDeprecated(conversation)
        RAINBOW_ONCONVERSATIONCHANGED, RAINBOW_ONCONVERSATIONREMOVED, RAINBOW_ONCONVERSATIONSCHANGED, RAINBOW_ONCONVERSATIONSMISSEDCOUNTERCHANGED
    }
    export class IConversations {
        static get ONCHANGED(): ReplaySubject<any> {return  subscribe(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONSCHANGED)};
        static get ONMISSEDCOUNTERCHANGED(): ReplaySubject<any> {return  subscribe(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONSMISSEDCOUNTERCHANGED)};

    }
    export class IConversation extends Conversation {
        static get ONNEWMESSAGE(): ReplaySubject<any> {return  subscribe(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONCHANGED)};
        onNewMessage: ReplaySubject<any> = new ReplaySubject<any>(1);
        onChanged: ReplaySubject<any> = new ReplaySubject<any>(1);
        constructor(conversation?:Conversation) {
            super();
            if (conversation)
            {
                Object.assign(this,conversation);
                IConversation.ONNEWMESSAGE.subscribe(conversationId=>{
                    if (conversationId==this.id) {
                        this.onNewMessage.next(true)
                    }
                })
                IConversations.ONCHANGED.subscribe((conversation: Conversation)=>{
                    if (conversation.id==this.id) {
                        this.onChanged.next(true)
                    }
                })
            }
        }
        mute() {
            IWebRTC.mute(this)
        }
        unmute() {
            IWebRTC.unmute(this)
        }
    }
    //endregion

    export interface FavoritesService {
        fetchAllFavorites() :Promise<any>
        createFavorite(id, favoriteType) :Promise<any>
        deleteFavorite(id) :Promise<any>
        RAINBOW_ONFAVORITECREATED, RAINBOW_ONFAVORITEDELETED
    }
    export interface FileStorageService {
        uploadFileToConversation(conversationId, file, message) : Promise<any>
        uploadFile(file, message, progressCallback) : Promise<any>
        uploadExistingFileToConversation(conversationId, fileDescriptor, message) : Promise<any>
        uploadFileToBubble(bubble, file, message) : Promise<any>
        downloadFile(fileDescriptor) : Promise<any>
        removeFile(fileDescriptor) : Promise<any>
        getFileDescriptorFromId(id) : object
        getFileDescriptorUrl(fileDescriptor, thumbnail) : object
        getFilesReceivedInConversation(conversationId) : Promise<any>
        getFilesReceivedInBubble(bubble) : Promise<any>
        getFilesSentInConversation(conversationId) : Promise<any>
        getFilesSentInBubble(bubble) : Promise<any>
        getUserQuotaConsumption() : Promise<any>
        getAllFilesSent() : Array<any>
        getAllFilesReceived() : Array<any>
        cancelCurrentFileTransfer(id) : Promise<any>
        RAINBOW_ONCHUNKLOADMESSAGE, RAINBOW_ONFILEUPLOADED, RAINBOW_ONFILEUPLOADED_ERROR, RAINBOW_ONFILEDOWNLOADED, RAINBOW_ONFILEDOWNLOADED_ERROR
    }
    export interface GroupsService {
        createGroup(strName, strDescription, isFavorite) :Promise<any>
        deleteGroup(group) :Promise<any>
        deleteAllGroups() :Promise<any>
        getAll() : Array<any>
        getFavoritesGroup() : Array<any>
        setGroupAsFavorite(group) :Promise<any>
        getGroupById(groupId) : object
        getDetailedGroupById(id) :Promise<any>
        addContactInGroup(contactId, group) :Promise<any>
        removeContactFromGroup(contactId, group) :Promise<any>
        unsetGroupAsFavorite(group) : Promise<any>
        RAINBOW_ONGROUPCREATED, RAINBOW_ONGROUPUPDATED, RAINBOW_ONGROUPDELETED, RAINBOW_ONGROUPUSERADDED, RAINBOW_ONGROUPUSERREMOVED
    }

    //region Message code
    export class Message {
        alternativeContent : any;
        answeredMsgId : string= ''
        id : string = ''
        type : string = ''
        data : string = ''
        date : Date = null;
        senderId : string = ''
        isForwarded : boolean = false
        receiptStatus : number = 0
        status : string = ''
        urgency : string = ''
        shortFileDescriptor : any = {}
        fileId : string = ''
        messageHistory : Array<any> = []
        side: 'L' | 'R' | 'ADMIN'
        sendData: {conversation?: Conversation, txt?:string, altContent?: string, altContentType?: string} = {}
        conversation: Conversation
    }
    export class IMessages {
        static get ONRECEIVED(): ReplaySubject<any> {return subscribe(rainbowSDK.im.RAINBOW_ONNEWIMMESSAGERECEIVED)};

    }
    export class IMessage extends Message {
        onReceived: ReplaySubject<Message> = new ReplaySubject<Message>();
        constructor() {
            super()
            let s = IMessages.ONRECEIVED;
            s.subscribe((data:{message:Message, conversation: Conversation, cc: boolean})=>{
                console.log(`[RB-CORE] :: IMessage message received, comparing to ${this.id}`)
                if (data.message.answeredMsgId == this.id) {
                    console.log(`[RB-CORE] :: IMessage received response to me ${this.id}`);
                    this.onReceived.next(data.message);
                    s.unsubscribe();
                }
            })
            console.log(`[RB-CORE] :: IMessage created`)
        }
        withTxt(txt:string): this {
            this.sendData.txt = txt;
            return this;
        }
        withAltContent(txt:string, type='text/markdown'): this {
            this.sendData.altContent = txt;
            this.sendData.altContentType = type;
            return this;
        }
        async send(contact: Contact|string, waitForReply=false): Promise<Message> {
            if (!this.sendData.txt) return Promise.reject(`No message text`);
            this.conversation = await rainbowSDK.conversations.getConversationByContactId(typeof contact == 'string'?contact:contact.id);
            if (!this.conversation) return Promise.reject(`No conversation`);
            return new Promise((resolve, reject)=>{
                let wd = setTimeout(() => {
                    s.unsubscribe();
                    console.error(`[RB-CORE] :: IMessage No reply to message, timeout`);
                    reject('Timeout')
                }, 5000);
                let s = this.onReceived.pipe(delay(1000)).subscribe(replyMsg => {
                    //console.warn(`Received msg`, replyMsg)
                    if (replyMsg.answeredMsgId == this.id) {
                        clearTimeout(wd);
                        console.log(`[RB-CORE] :: IMessage got a msgReply for ${this.id}`)
                        resolve(replyMsg)
                    } else {
                        console.warn(`[RB-CORE] :: IMessage got message while waiting for ${this.id}, ignoring`, replyMsg)
                    }
                })
                let message = rainbowSDK.im.sendMessageToConversation(this.conversation.id, this.sendData.txt, this.sendData.altContentType, this.sendData.altContent)
                Object.assign(this, message);
                if (!waitForReply) {
                    clearTimeout(wd);
                }
            })
        }

        async reply(): Promise<Message> {
            if (!this.sendData.txt) return Promise.reject(`No message text`);
            if (!this.conversation) return Promise.reject(`No conversation`);
            return new Promise((resolve, reject)=>{
                rainbowSDK.im.replyToMessage(this.conversation.id, this.sendData.txt, this.id).then(message=>{
                    //Object.assign(this, message);
                }).catch(err=>reject(err))
            })
        }
    }
    export interface ImService {
        getMessagesFromConversation(conversationId, intNbMessage) :Promise<any>
        getMessageFromConversationById(conversationId, strMessageId) : object
        getMessageFromBubbleById(bubble, strMessageId) : object
        sendMessageToConversation(conversationId, strMessage, alternativeContentType, alternativeContentMessage, urgency?) : Message
        sendCorrectedChatMessage(conversationId, strMessage, messageId) : object
        replyToMessage(conversationId, strMessage, answerMessageId) :Promise<any>
        deleteMessage(conversationId, messageId) : object
        getLastModifiedText(conversationId, messageId) : string
        isMessageDeleted(conversationId, messageId) : boolean
        sendIsTypingStateInConversation(conversationId, status) :Promise<any>
        sendMessageToBubble(bubble, strMessage) :Promise<any>
        sendIsTypingStateInBubble(bubble, status) : object
        removeAllMessagesFromConversation(conversationId) :Promise<any>
        markMessageFromConversationAsRead(conversationId, messageId) : object
        RAINBOW_ONNEWIMMESSAGERECEIVED, RAINBOW_ONNEWIMRECEIPTRECEIVED, RAINBOW_ONNEWPARTICIPANTSTATUS
    }
    //endregion

    //region Presence code
    export interface PresenceService {
        setPresenceTo(strPresenceState)
        RAINBOW_ONPRESENCECHANGED, RAINBOW_ONCONTACTPRESENCECHANGED, RAINBOW_ONRICHPRESENCECHANGED, RAINBOW_ONCONTACTRICHPRESENCECHANGED
    }
    export class IPresence {
        static get OFCONTACTCHANGED(): ReplaySubject<Rainbow.Contact> {return subscribe(rainbowSDK.presence.RAINBOW_ONCONTACTRICHPRESENCECHANGED)};
    }
    export namespace IPresence {
        export enum State {OFFLINE='offline', ONLINE='online'}
    }
    //endregion

    export interface TelephonyService {
        isTelephonyAvailable() : boolean
        isVoiceMailConfigured() : boolean
        getAgentVersion() : string
        getXMPPAgentStatus() : string
        getPhoneAPIStatus() : string
        makeCall1PCC(strPhoneNumber, contactId) : Promise<any>
        makeCall(contactId, strPhoneNumber, callSubject, correlatorData) : Promise<any>
        callByNumber(strPhoneNumber) : Promise<any>
        callWithMessage(contactId, strPhoneNumber, callSubject, correlatorData) : Promise<any>
        callWithSubject(contactId, phoneNumber, callSubject) : Promise<any>
        calls() : Array<any>
        getActiveCall() : object
        release(call) : Promise<any>
        deflectToVM(call) : object
        forwardToDevice(strPhoneNumber) : Promise<any>
        forwardToVoicemail() : Promise<any>
        cancelForward() : Promise<any>
        getForwardStatusFromServer() : Promise<any>
        getForwardStatus() : object
        answer(call) : Promise<any>
        hold(call) : Promise<any>
        retrieve(call) : Promise<any>
        transferCall(activeCall, heldCall) : Promise<any>
        conferenceCall(activeCall, heldCall) : Promise<any>
        fetchVoiceMailMessagesNumber() : Promise<any>
        muteCall(call, conversation) : void
        unmuteCall(call, conversation) : void
        nomadicLogin(phoneNumber, isUnchangeableNumber, notTakeIntoAccount) : Promise<any>
        nomadicLogout() : Promise<any>
        getNomadicState() : Promise<any>
        setExternalNomadicNumber(phoneNumber) : Promise<any>
        setNomadicOfficePhone(ringOnlyOfficePhone) : Promise<any>
        setNomadicVoIP() : Promise<any>
        _setNomadicWebRTC() : Promise<any>
        isDeskPhoneAvailable() : boolean
        isVoIPAvailable() : boolean
        logonCCD(endpointTel, agentId, password, groupId) : Promise<any>
        logoffCCD(endpointTel, agentId, password, groupId) : Promise<any>
        withdrawalCCD(agentId, groupId, status) : Promise<any>
        wrapupCCD(agentId, groupId, password, status) : Promise<any>
        sendDTMF(dialString, call) : Promise<any>
        RAINBOW_ONTELEPHONYSTARTED, RAINBOW_ONTELEPHONYSTOPPED, RAINBOW_ONTELEPHONYCALLSTATECHANGED, RAINBOW_ONVOIPCALLSTATECHANGED, RAINBOW_ONTELEPHONYFORWARDSTATECHANGED, RAINBOW_ONCALLNOMADICEVENT, RAINBOW_ONVOIPSTARTED, RAINBOW_ONVOIPSTOPPED, RAINBOW_ONVOICEMESSAGEUPDATED
    }
    export interface UserProfileService {
        getProfile() : object
        getProfileOffer() : string
        updateFirstName(strFirstname) : Promise<any>
        updateLastName(strLastName) : Promise<any>
        updatePhoneNumber(type, deviceType, strPhoneNumber, country) : Promise<any>
        deletePhoneNumber(type, deviceType) : Promise<any>
        updatePhotoFromUrl(imgUrl) : Promise<any>
        updateWorkEmail(strEmail) : Promise<any>
        updatePersonalEmail(strEmail) : Promise<any>
        deleteWorkEmail() : Promise<any>
        deletePersonalEmail() : Promise<any>
        updateCountry(countryStr) : Promise<any>
        updateLanguage(languageStr) : Promise<any>
        updateTitle(titleStr) : Promise<any>
        updateNickName(nickNameStr) : Promise<any>
        updateJobTitle(jobTitleStr) : Promise<any>
    }

    //region WebRTC Code
    export interface WebRTCService {
        canMakeAudioVideoCall() : boolean
        hasAMicrophone() : boolean
        hasACamera() : boolean
        useMicrophone(strMicrophoneId) : object
        useSpeaker(strSpeakerId) : object
        useCamera(strCameraId) : object
        callInAudio(contactId, subject?) : Promise<any>
        callInVideo(contactId, subject?) : Promise<any>
        callInSharing(contactId, subject?) : Promise<any>
        callinAudioWithSharing(contactId, subject?) : Promise<any>
        answerInAudio(call) : object
        answerInVideo(call) : object
        getPeerConnectionForCall(call) : object
        showLocalVideo() : object
        hideLocalVideo() : object
        showRemoteVideo(call) : object
        hideRemoteVideo(call) : object
        addVideoToCall(call) : object
        addSharingToCall(call) : object
        removeVideoFromCall(call) : object
        removeSharingFromCall(call) : object
        release(call) : object
        muteAudioCall(conversation) : object
        unmuteAudioCall(conversation) : object
        getLocalStreamsFromCall(call) : Array<MediaStream>
        getRemoteStreamsFromCall(call) : object
        setChromeExtensionIdForSharing(strExtensionId) : object
        canMakeDesktopSharingCall() : object
        startRecording(call, fileName, recordRemote?, toUpload?, onlyAudio?, mimeType?) : object
        stopRecording(call) : object
        RAINBOW_ONWEBRTCCALLSTATECHANGED, RAINBOW_ONWEBRTCERRORHANDLED, RAINBOW_ONWEBRTCSTREAMADDED, RAINBOW_ONWEBRTCTRACKCHANGED, RAINBOW_ONCHROMESTOPSCREENSHARINGTRIGGERED, RAINBOW_ONWEBRTCTMEDIAERROROCCURED, RAINBOW_ONWEBRTCTRECORDDONE, RAINBOW_ONWEBRTCTRECORDERROR, RAINBOW_ONWEBRTCTRECORDSTARTED, RAINBOW_ONWEBRTCTRECORDSTOPPED, RAINBOW_ONWEBRTCTRECORDREMOTESTARTED, RAINBOW_ONWEBRTCTRECORDREMOTESTOPPED
    }

    export class IWebRTC {
        call: Rainbow.Call
        callActive: boolean = false;
        callStatus: Rainbow.Call.Status;
        onCallStatusChange: ReplaySubject<any> = new ReplaySubject<any>()
        onCallActive: ReplaySubject<any> = new ReplaySubject<any>()
        constructor() {
            IWebRTC.ONTRACKCHANGED.subscribe(data=>{
                console.warn(`[RB-CORE] :: IWebRTC Call track change: ${JSON.stringify(data)}`)
            })
            IWebRTC.ONCALLSTATECHANGED.subscribe((call: Rainbow.Call)=>{
                this.call = call;
                this.callStatus = call.status.value
                switch (call.status.value) {
                    case Rainbow.Call.Status.ACTIVE:
                        switch (this.callStatus) {
                            default:
                                if (!this.callActive) {
                                    this.callActive = true;
                                    this.onCallActive.next(true);
                                }
                                break;
                        }
                        break;
                    case Rainbow.Call.Status.UNKNOWN:
                        if (this.callActive) {
                            this.callActive = false;
                            this.call=null;
                            this.onCallActive.next(false);
                        }
                        break;
                    case Rainbow.Call.Status.ANSWERING:
                        break;
                    case Rainbow.Call.Status.RINGING_INCOMMING:
                        break;
                    default:
                        console.log(`[RB-CORE] :: Unknown call state`, call.status.value)
                        break;
                }
                console.warn(`[RB-CORE] :: IWebRTC Call state change to ${JSON.stringify(call.status.value)}`)
                this.onCallStatusChange.next(this.callStatus)
            })
        }
        static get ONCALLSTATECHANGED(): ReplaySubject<any> {return  subscribe(rainbowSDK.webRTC.RAINBOW_ONWEBRTCCALLSTATECHANGED)};
        static get ONTRACKCHANGED(): ReplaySubject<any> {return  subscribe(rainbowSDK.webRTC.RAINBOW_ONWEBRTCTRACKCHANGED)};
        static mute(conversation) {rainbowService.sdk.webRTC.muteAudioCall(conversation)}
        static unmute(conversation) {rainbowService.sdk.webRTC.unmuteAudioCall(conversation)}
        static get hasActiveCall(): boolean {return rainbowService.webRTC.callActive}
        static get activeCall(): Rainbow.ICall {return new Rainbow.ICall(rainbowService.webRTC.call)}
    }
    //endregion

    //region Call Code
    export class Call {
        id
        conversationId
        contact
        localMedia
        isEscalated
        startDate
        isInitiator
        participants
        isRemoteVideoMuted
        isConference
        //avatars
        //subject
        currentCalled
        fullJid
        mediaPillarCall
        isSecondary
        isOwner
        isMuted
        duration
        activeControl
        hasRemoteControlling
        networkQuality
        status: {value:Call.Status, key:  number}
    }
    export namespace Call {
        export enum Status {
            UNKNOWN = 'Unknown', DIALING = 'dialing', QUEUED_INCOMMING = 'queuedIncomming', QUEUED_OUTGOING = 'queuedOutGoing', RINGING_INCOMMING = 'incommingCall',
            RINGING_OUTGOING = 'ringingOutgoing', ACTIVE = 'active', HOLD = 'held', PUT_ON_HOLD = 'putOnHold', RELEASING = 'releasing', ANSWERING = 'answering', CONNECTING = 'connecting',
            ERROR = 'error'
        }

        export enum Type {WEBRTC, PHONE}

        export enum Media {AUDIO = 1, VIDEO = 2, PHONE = 4, SHARING = 8}
    }

    export class ICall extends Call{
        constructor(call?:Call) {
            super();
            if (call)
            {
                Object.assign(this,call);
            }
        }
        mute() {
            //TODO issue with conversationid, waiting for fix
            return;
            let conversation = new IConversation(rainbowService.sdk.conversations.getConversationById(this.conversationId));
            conversation.mute()
        }
        unmute() {
            //TODO issue with conversationid, waiting for fix
            return;
            let conversation = new IConversation(rainbowService.sdk.conversations.getConversationById(this.conversationId));
            conversation.unmute()
        }
        release() {
            rainbowService.sdk.webRTC.release(this.id)
        }
    }
    //end region


    export class REST {
        token = '';
        static version = '/v1.0';
        static prefix = '/api/rainbow';
        _isSandbox: boolean = false;
        get isSandbox() {
            return this._isSandbox;
        };

        set isSandbox(value) {
            this._isSandbox = value;
            this.url = this.endpoint + REST.prefix;
            this.httpRequestConfig.baseURL = this.endpoint;
        }

        get endpoint() {
            return this.isSandbox ? REST.endpointSandbox : REST.endpointOfficial;
        }

        static endpointOfficial = 'https://openrainbow.com';
        static endpointSandbox = 'https://sandbox.openrainbow.com';
        url = this.endpoint + REST.prefix;
        httpRequestConfig: AxiosRequestConfig = {
            baseURL: this.endpoint,
            timeout: 3000,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'x-rainbow-client-version': '1.10.7',
                'x-rainbow-client': 'sdk_web'
            }
        };
        http = axios.create();

        get authentication(): REST.Authentication {
            this.url = this.endpoint + REST.prefix + REST.API.AUTHENTICATION + REST.version;
            return new REST.Authentication(this);
        }

        get admin(): REST.Admin {
            this.url = this.endpoint + REST.prefix + REST.API.ADMIN + REST.version;
            return new REST.Admin(this);
        }

        get get(): Promise<any> {
            this.httpRequestConfig.url = this.url;
            this.httpRequestConfig.method = 'get';
            this.httpRequestConfig.headers['Authorization'] = 'Bearer ' + this.token;
            return new Promise<any>((resolve, reject) => {
                //console.log('[ALE-RB] :: ngxRainbow REST get', this.httpRequestConfig);
                this.http.request(this.httpRequestConfig).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            });
        }

        get create(): Promise<any> {
            this.httpRequestConfig.url = this.url;
            this.httpRequestConfig.method = 'post';
            this.httpRequestConfig.headers['Authorization'] = 'Bearer ' + this.token;
            return new Promise<any>((resolve, reject) => {
                //console.log('[ALE-RB] :: ngxRainbow REST create', this.httpRequestConfig);
                this.http.request(this.httpRequestConfig).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            });
        }

        get update(): Promise<any> {
            this.httpRequestConfig.url = this.url;
            this.httpRequestConfig.method = 'put';
            this.httpRequestConfig.headers['Authorization'] = 'Bearer ' + this.token;
            return new Promise<any>((resolve, reject) => {
                //console.log('[ALE-RB] :: ngxRainbow REST update', this.httpRequestConfig);
                this.http.request(this.httpRequestConfig).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            });
        }

        get delete(): Promise<any> {
            this.httpRequestConfig.url = this.url;
            this.httpRequestConfig.method = 'delete';
            this.httpRequestConfig.headers['Authorization'] = 'Bearer ' + this.token;
            return new Promise<any>((resolve, reject) => {
                //console.log('[ALE-RB] :: ngxRainbow REST delete', this.httpRequestConfig);
                this.http.request(this.httpRequestConfig).then(data => {
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
            });
        }

        constructor(token = '', isSandbox = false, username = '', passwd = '') {
            this.isSandbox = isSandbox;
            this.token = token;
        }

    }

    //Namespace REST
    export namespace REST {

        class Base {
            rest: REST;

            constructor(rest: REST) {
                this.rest = rest;
            }
        }

        //REST API Modules
        export enum API {AUTHENTICATION = '/authentication', ADMIN = '/admin'}

        //REST Authentication module declarations
        export enum AUTHENTICATION {LOGIN = '/login', LOGOUT = '/logout', VALIDATOR = '/validator', RENEW = '/renew', AUTHORIZE_OAUTH='/oauth/token'}

        export class Authentication extends Base {
            constructor(rest) {
                super(rest);
            }

            /*login(loginEmail, password, secret, key): Promise<any> {
              this.rest.url = this.rest.url + REST.AUTHENTICATION.LOGIN;
              this.rest.httpRequestConfig.url = this.rest.url;
              this.rest.httpRequestConfig.method = 'get';
              return new Promise<any>((resolve, reject) => {
                let auth = btoa(loginEmail + ':' + password);
                let toEncrypt = secret + password;
                let encrypted = CryptoJS.SHA256(toEncrypt).toString();
                let base64 = btoa(key + ':' + encrypted);
                this.rest.httpRequestConfig.headers['Authorization'] = 'Basic ' + auth;
                this.rest.httpRequestConfig.headers['x-rainbow-app-auth'] = 'Basic ' + base64;
                console.log('[ALE-RB] :: APIlogin', loginEmail, password, secret, key, this.rest.httpRequestConfig);
                this.rest.http.request(this.rest.httpRequestConfig).then(response => {
                  this.rest.token = response.data.token;
                  resolve(response.data);
                }).catch(error => {
                  reject({msg: error.response.data.errorDetails, code: error.response.data.errorDetailsCode, message: error.message});
                });
              });
            }*/

            get logout(): REST {
                this.rest.url = this.rest.url + REST.AUTHENTICATION.LOGOUT;
                return this.rest;
            }

            get validateJWT(): REST {
                this.rest.url = this.rest.url + REST.AUTHENTICATION.VALIDATOR;
                return this.rest;
            }

            ///api/rainbow/authentication/v1.0/oauth/token
            get authorizeOauth(): REST {
                this.rest.url = this.rest.url + REST.AUTHENTICATION.AUTHORIZE_OAUTH;
                return this.rest;
            }


            get renew(): REST {
                this.rest.url = this.rest.url + REST.AUTHENTICATION.VALIDATOR;
                return this.rest;
            }
        }

        export namespace AUTHENTICATION {
        }

        //REST Admin module declarations
        export enum ADMIN {COMPANIES = '/companies', ORGANISATIONS = '/organisations', USERS = '/users'}

        export class Admin extends Base {
            constructor(rest) {
                super(rest);
                console.log('[ALE-RB] :: Admin class constructor', this.rest);
            }

            companies(companyId?): REST.ADMIN.Companies {
                this.rest.url = this.rest.url + REST.ADMIN.COMPANIES + (companyId ? '/' + companyId : '');
                return new REST.ADMIN.Companies(this.rest);
            }

            users(userId?): REST.ADMIN.Users {
                this.rest.url = this.rest.url + REST.ADMIN.USERS + (userId ? '/' + userId : '');
                return new REST.ADMIN.Users(this.rest);
            }
        }

        export namespace ADMIN {
            export class Companies extends Base {
                static sites = '/sites';
                static banner = '/banner';
                static default: '/default';

                constructor(rest: REST) {
                    super(rest);
                }

                params(params: { format?: REST.Params.format }): REST {
                    this.rest.httpRequestConfig.params = params;
                    return this.rest;
                }

                body(body: Company): REST {
                    this.rest.httpRequestConfig.data = body;
                    return this.rest;
                }

                sites(companyId): REST.ADMIN.Companies.Sites {
                    this.rest.url = this.rest.url + ADMIN.Companies.sites;
                    return this;
                }

                get default(): REST {
                    this.rest.url = this.rest.url + ADMIN.Companies.default;
                    return this.rest;
                }

            }

            export namespace Companies {
                export class Sites extends Base {
                    constructor(rest) {
                        super(rest);
                    }

                    params(params: { format?: REST.Params.format }): REST {
                        this.rest.httpRequestConfig.params = params;
                        return this.rest;
                    }
                }
            }

            export class Users extends Base {
                static profiles = '/profiles';
                static networks = '/networks';

                constructor(rest) {
                    super(rest);
                    console.log('[ALE-RB] :: Users class constructor', this.rest);
                }

                params(params: Users.params): REST {
                    this.rest.httpRequestConfig.params = params;
                    return this.rest;
                }

                body(body: User): REST {
                    this.rest.httpRequestConfig.data = body;
                    return this.rest;
                }
            }

            export namespace Users {
                export interface params {
                    format?: REST.Params.format,
                    searchEmail?: string,
                    companyId?: string,
                    roles?: string,
                    isTerminated?: string,
                    limit?: number,
                    offset?: number,
                    sortField?: string,
                    sortOrder?: number,
                    displayName?: string,
                    useEmails?: boolean,
                    companyName?: string,
                    loginEmail?: string,
                    email?: string,
                    visibility?: string,
                    organisationId?: string,
                    siteId?: string,
                    jid_im?: string,
                    jid_tel?: string
                }
            }

            export class Organisations {
                static companies = '/companies';
            }

            export namespace Organisations {

            }
        }

        export interface User {
            companyId?: string,
            loginEmail: string,
            password: string,
            firstName?: string,
            lastName?: string,
            nickName?: string,
            title?: string,
            jobTitle?: string,
            emails?:
                {
                    email: string,
                    type: 'home' | 'work' | 'other'
                }[],
            phoneNumbers?:
                {
                    number: string,
                    country: string,
                    type: 'home' | 'work' | 'other',
                    deviceType: User.PhoneDeviceType
                }[],
            country?: string,
            state?: User.State,
            language?: string,
            timezone?: string,
            accountType?: User.AccountType,
            roles?: User.Role[],
            adminType?: User.AdminType,
            isActive?: true,
            isInitialized?: false,
            visibility?: User.Visibility,
            timeToLive?: number,
            authenticationType?: User.AuthenticationType
        }

        export namespace User {
            export type Role =
                'guest'
                | 'user'
                | 'admin'
                | 'bp_admin'
                | 'bp_finance'
                | 'company_support'
                | 'all_company_channels_admin'
                | 'public_channels_admin'
                | 'closed_channels_admin'
                | 'app_admin'
                | 'app_support'
                | 'app_superadmin'
                | 'support'
                | 'superadmin'
            export type AccountType = 'free' | 'basic' | 'advanced'
            export type State =
                'null'
                | 'AA'
                | 'AE'
                | 'AP'
                | 'AK'
                | 'AL'
                | 'AR'
                | 'AZ'
                | 'CA'
                | 'CO'
                | 'CT'
                | 'DC'
                | 'DE'
                | 'FL'
                | 'GA'
                | 'GU'
                | 'HI'
                | 'IA'
                | 'ID'
                | 'IL'
                | 'IN'
                | 'KS'
                | 'KY'
                | 'LA'
                | 'MA'
                | 'MD'
                | 'ME'
                | 'MI'
                | 'MN'
                | 'MO'
                | 'MS'
                | 'MT'
                | 'NC'
                | 'ND'
                | 'NE'
                | 'NH'
                | 'NJ'
                | 'NM'
                | 'NV'
                | 'NY'
                | 'OH'
                | 'OK'
                | 'OR'
                | 'PA'
                | 'PR'
                | 'RI'
                | 'SC'
                | 'SD'
                | 'TN'
                | 'TX'
                | 'UT'
                | 'VA'
                | 'VI'
                | 'VT'
                | 'WA'
                | 'WI'
                | 'WV'
                | 'WY'
                | 'AB'
                | 'BC'
                | 'MB'
                | 'NB'
                | 'NL'
                | 'NS'
                | 'NT'
                | 'NU'
                | 'ON'
                | 'PE'
                | 'QC'
                | 'SK'
                | 'YT'
            export type AdminType = 'organization_admin' | 'company_admin' | 'site_admin'
            export type Visibility = 'private' | 'public' | 'none'
            export type AuthenticationType = 'DEFAULT' | 'RAINBOW' | 'SAML'
            export type PhoneDeviceType = 'landline' | 'mobile' | 'fax' | 'other'
        }
        export namespace Params {
            export type format = 'small' | 'medium' | 'full';
        }

        export interface Company {
            status?: Company.Status,
            privateDC?: string,
            name: string,
            country?: string,
            street?: string,
            city?: string,
            state?: User.State,
            postalCode?: string,
            currency?: string,
            visibility?: Company.Visibility,
            visibleBy?: string[],
            adminEmail?: string,
            supportEmail?: string,
            companyContactId?: string,
            userSelfRegisterEnabled?: true,
            userSelfRegisterAllowedDomains?: string[],
            slogan?: string,
            description?: string,
            size?: Company.Size,
            economicActivityClassification?: Company.EconomicActivityClassificationType,
            website?: string,
            giphyEnabled?: boolean,
            catalogId?: string,
            customData?: {
                key1: string,
                key2: string
            },
            bpId?: string,
            adminHasRightToUpdateSubscriptions?: true,
            adminAllowedUpdateSubscriptionsOps?: Company.AdminAllowedUpdateSubscriptionsOps,
            isBP?: true,
            bpType?: Company.BpType,
            bpBusinessModel?: Company.BpBusinessModel,
            bpApplicantNumber?: string,
            bpCRDid?: string,
            bpHasRightToSell?: boolean,
            bpHasRightToConnect?: boolean,
            bpIsContractAccepted?: false,
            externalReference?: string,
            externalReference2?: string,
            avatarShape?: Company.AvatarShape,
            isCentrex?: boolean,
            companyCallNumber?: string
        }

        export namespace Company {
            export type Status = 'initializing' | 'active' | 'alerting' | 'hold' | 'terminated';
            export type Visibility = 'public' | 'private' | 'organisation'
            export type EconomicActivityClassificationType =
                'A'
                | 'B'
                | 'C'
                | 'D'
                | 'E'
                | 'F'
                | 'G'
                | 'H'
                | 'I'
                | 'J'
                | 'K'
                | 'L'
                | 'M'
                | 'N'
                | 'O'
                | 'P'
                | 'Q'
                | 'R'
                | 'S'
                | 'T'
                | 'U'

            export enum EconomicActivityClassification {
                'A' = ' AGRICULTURE, FORESTRY AND FISHING',
                'B' = ' MINING AND QUARRYING',
                'C' = ' MANUFACTURING',
                'D' = ' ELECTRICITY, GAS, STEAM AND AIR CONDITIONING SUPPLY',
                'E' = ' WATER SUPPLY; SEWERAGE, WASTE MANAGEMENT AND REMEDIATION ACTIVITIES',
                'F' = ' CONSTRUCTION',
                'G' = ' WHOLESALE AND RETAIL TRADE; REPAIR OF MOTOR VEHICLES AND MOTORCYCLES',
                'H' = ' TRANSPORTATION AND STORAGE',
                'I' = ' ACCOMMODATION AND FOOD SERVICE ACTIVITIES',
                'J' = ' INFORMATION AND COMMUNICATION',
                'K' = ' FINANCIAL AND INSURANCE ACTIVITIES',
                'L' = ' REAL ESTATE ACTIVITIES',
                'M' = ' PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES',
                'N' = ' ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES',
                'O' = ' PUBLIC ADMINISTRATION AND DEFENCE; COMPULSORY SOCIAL SECURITY',
                'P' = ' EDUCATION',
                'Q' = ' HUMAN HEALTH AND SOCIAL WORK ACTIVITIES',
                'R' = ' ARTS, ENTERTAINMENT AND RECREATION',
                'S' = ' OTHER SERVICE ACTIVITIES',
                'T' = ' ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS; UNDIFFERENTIATED GOODS- AND SERVICES-PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE',
                'U' = ' ACTIVITIES OF EXTRATERRITORIAL ORGANISATIONS AND BODIES'
            }

            export type Size =
                'self-employed'
                | '1-10 employees'
                | '11-50 employees'
                | '51-200 employees'
                | '201-500 employees'
                | '501-1000 employees'
                | '1001-5000 employees'
                | '5001-10,000 employees'
                | '10,001+ employees'
            export type AdminAllowedUpdateSubscriptionsOps = 'all' | 'increase_only'
            export type BpType = 'IR' | 'VAD' | 'DR'
            export type BpBusinessModel = 'referral' | 'resell'
            export type AvatarShape = 'square' | 'circle'
        }
    }

    //End Namespace REST

}
